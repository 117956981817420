.g-manage-content-component {
    margin-top: 26px;

    .ant-table-thead{
        .ant-table-cell{
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #84818A;
        }
    }
    
    .g-manage-content-component-title-wrapper {
        display: flex;
        margin-bottom: 20px;

        .g-manage-content-title-segment-div {
            flex: 1;

            h3 {
                display: inline-block;
            }

            .ant-segmented {
                margin-left: 24px;
            }
        }

        .g-manage-content-search-add-btn-div {
            display: flex;
            height: 42px;

            .ant-btn {
                font-weight: 700;
                height: 42px;
            }

            .ant-input-affix-wrapper {
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
                width: 262px;
                border: unset;
                margin-right: 20px;
            }
        }
    }
}