.g-request-content-container {
    .g-request-content-card {
        padding: 30px;
        margin-bottom: 30px;
        background-image: linear-gradient(to right, #1C1F4B, #1C1F4B, #1c1f4bc6, #49a8c7, #94e596);
        border-radius: 8px;

        .ant-btn {
            border-radius: 4px;
            border: 1px solid #2B306A;
            margin-top: 14px;
            font-size: 16px;
            font-weight: 600;
            background-color: unset;

            &:hover {
                opacity: 0.7;
                transition: all 0.3s ease;
            }
        }

        * {
            color: white;
        }
    }

    .g-request-content-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .ant-btn {
            font-weight: 700;
        }

        .g-request-content-search-input {
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
            border: unset;
        }

        .g-video-upload-button {
            height: 38px;
            padding: 0 16px;
            margin: 0 0 0 5px;
            padding: 0 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            .g-video-upload-button-content {
                display: flex;

                .g-video-upload-button-content-tooltip-icon {
                    display: flex;
                    align-items: center;
                }

                .g-video-upload-button-content-tooltip-text {
                    margin: 0 8px;
                }

                .g-video-upload-button-content-drop-icon {
                    margin-left: 42px;
                }
            }
        }
    }

    .g-request-content-table-container {
        padding: 24px;
        border-radius: 8px;
        background-color: white;

        .g-list-url-name-container {
            .g-list-url-name {
                max-width: clamp(500px, 50vw, 50vw);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-bottom: 5px;
                color: var(--primary-blue, #3C8EF9);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }

        .g-list-date-uploaded-container {
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            .g-list-date-uploaded-date {
                color: var(--fill-dark-main-dark, #2E2C34);
            }

            .g-list-date-uploaded-time {
                color: var(--fill-dark-dark-2, #84818A);
            }
        }

        .ant-table-content {
            th.ant-table-cell {
                color: var(--grey, #84818A);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: DM Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            td.ant-table-cell {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }
    }

    .g-external-upload-content-table-container {
        padding: 24px;
        border-radius: 8px;
        background-color: white;

        .ant-table-content {
            th.ant-table-cell {
                color: var(--grey, #84818A);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: DM Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            td.ant-table-cell {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }

        .g-manage-content-spacer-action-items {
            .ant-btn {
                height: 28px;
                font-size: 12px;
                border: 1px solid #EFEFEF;
                font-weight: 600;
                line-height: 20px;
                box-shadow: none;
            }

            .g-manage-content-spacer-action-items-delete-button {
                display: flex;
                align-items: center;
            }
        }

        //columns file styles here
        .g-list-video-container {
            display: flex;
            align-items: center;

            .g-list-video-player {
                height: 52px;
                width: 52px;
                margin: 10px 16px 10px 0px;

                .g-video-not-complete-container {
                    font-size: 10px;
                }

                .g-video-preview-thumbnail {
                    border-radius: 4px;
                }
            }

            .g-list-video-table-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 50vw;
            }

            .g-list-video-details {
                display: flex;

                div {
                    margin: 0 4px;
                }

                .g-list-video-details-dot {
                    background-color: #84818A;
                    border-radius: 50%;
                    height: 4px;
                    width: 4px;
                    margin: auto 5px;
                }
            }
        }


        .g-list-date-uploaded-container {

            .g-list-date-uploaded-date {
                font-weight: 600;
            }

            .g-list-date-uploaded-time {
                font-weight: 500;
                color: #84818A;
            }
        }

        .g-list-sources-container {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            .g-list-source-tag-loading {
                width: 90px;
                height: 20px;
            }
        }
    }

    .g-hide-request-content-tab {
        [data-node-key="2"] {
            opacity: 0;
            pointer-events: none;
        }
    }
}