@import 'assets/scss/mixing.scss';

.g-interaction-edit-results-card-container {
    border-radius: 0px 0px 8px 8px;
    height: 100%;
    overflow: hidden;

    // display: flex;
    .ant-card-body {
        padding: 0px;
        height: 100%;
        border-radius: inherit;
    }
    .g-interaction-loader {
        height: 400px;
    }

    .g-interaction-edit-sub-card-container {
        width: 100%;
        min-height: 400px;
        height: calc(100% - 130px);

        @include custom-scrollbar;
        overflow-y: auto;

    }
}