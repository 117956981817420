.g-overlay-preview-state-add-border-div {
  display: flex;
  align-items: center;

  .g-overlay-preview-state-border-input-div {
      margin-left: 2px;
      display: flex;
      align-items: center;
      border: 1px solid #E5E8E8;
      border-radius: 8px;
      padding: 6px 14px;
      cursor: pointer;

      &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
      }

      input {
          cursor: pointer;
          border: none;
          padding: 0;
          background: transparent;
          height: 15px;
          width: 15px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &:disabled {
              cursor: not-allowed;
          }

          &::-webkit-color-swatch-wrapper {
              padding: 0;
          }

          &::-webkit-color-swatch {
              border: 0;
              border-radius: 0;
          }

          &::-moz-color-swatch,
          &::-moz-focus-inner {
              border: 0;
          }

          &::-moz-focus-inner {
              padding: 0;
          }
      }

      span {
          margin-left: 10px;
          font-size: 12px;
          font-family: Manrope;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
      }
  }
}