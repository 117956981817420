.g-interation-edit-preview-panel-cta-interaction-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 7px;

  .g-interation-edit-preview-panel-cta-interaction-button-container {
    display: flex;
    justify-content: center;
    height: 40px;
    border-radius: 4px;

    .g-interation-edit-preview-panel-cta-interaction-button {
      display: flex;
      justify-content: center;
      padding: 10px;

      .g-interation-edit-preview-panel-cta-interaction-text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  .g-preview-cta-interaction-button-container-default {
    height: 40px;

    .g-preview-cta-interaction-text-default {
      font-size: 12px;
    }
  }
  
  .g-preview-cta-interaction-button-container-large {
    height: 45px;

    .g-preview-cta-interaction-text-large {
      font-size: 16px;
    }
  }
}