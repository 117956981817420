.g-upload-video-component {
    margin: 26px 0 30px 0;

    .g-upload-video-upload-container {
        cursor: pointer;

        .g-upload-video-upload-box {
            display: flex;
            flex-direction: column;
            display: flex;
            width: 100%;

            .ant-upload-select {
                width: 100%;
                height: 120px;

                .g-upload-video-upload-box-content {
                    background: #F0F6FF;
                    border: 2px dashed var(--primary);
                    border-radius: 8px;
                    height: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .g-upload-video-upload-box-content-icon {
                        padding-right: 25px;
                    }

                    .g-upload-video-upload-box-content-text {

                        .g-upload-video-upload-box-title {
                            -webkit-user-select: none;
                            /* Safari */
                            -moz-user-select: none;
                            /* Firefox */
                            -ms-user-select: none;
                            /* IE10+/Edge */
                            user-select: none;
                            /* Standard */
                            display: flex;
                            font-size: 16px;
                        }

                        .g-upload-video-upload-box-description {
                            -webkit-user-select: none;
                            /* Safari */
                            -moz-user-select: none;
                            /* Firefox */
                            -ms-user-select: none;
                            /* IE10+/Edge */
                            user-select: none;
                            /* Standard */
                            display: flex;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }

}