.g-interaction-edit-question-edit-form-field-upload-input {
  height: 110px;
  width: 11px;
  margin: 0px;
}

.g-interaction-edit-question-edit-form-field-upload-cropper {
    .ant-modal-body button {
      bottom: 10px;
    }
  }