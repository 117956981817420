.g-subscription-card-container {
    border: 1.4px solid #E4E4E4;
    border-radius: 12px;
    width: 246px;
    min-width: 246px;
    padding: 40px 32px 12px 32px;
    margin-bottom: 20px;

    .g-subscription-card-most-popular-badge {
        font-weight: 800;
        font-size: 12px;
        background: #3C8EF9;
        color: white;
        border-radius: 99px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 10px;
        margin-left: 5px;
        margin-bottom: 6px;

        span {
            text-align: center;
        }
    }

    .g-subscription-card-price {
        margin-top: 10px !important;
        line-height: 56px;
        font-weight: 700;
    }

    .g-subscription-card-detail-box {
        height: 60px;
        display: flex;
        align-items: center;
        margin-top: 16px;
        // border: 1px solid gray;

        .g-subscription-card-detail-title {
            margin-left: 16px;

            h5.ant-typography {
                font-size: 14px;
                font-weight: 700;
            }

            .g-subscription-card-detail-title-detail {
                color: #84818A;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.02em;
            }
        }
    }

    .g-subscription-card-select-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        margin-top: 27px;
        width: 146px;

        .g-subscription-card-select-button {
            width: 100%;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-btn:disabled,
            a.ant-btn-disabled {
                width: 100%;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            a.ant-btn-disabled {
                color: rgba(0, 0, 0, 0.25);
                border-color: #d9d9d9;
                background: #f5f5f5;
                text-shadow: none;
                box-shadow: none;
                padding-top: 6px !important;
                // contact sales link button specific fix when other plans is selected and this button is in loading state
            }
        }

        a.g-subscription-card-select-button {
            padding-top: 6px !important;
            // contact sales link button specific fix
        }
    }
}