.g-interaction-edit-insights-card-header {
  display: flex;
  justify-content: space-between;

  .g-interaction-edit-insights-card-title-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .g-interaction-edit-insights-card-title {
      margin-bottom: 10px;
    }
    
    .g-interaction-edit-insights-card-sub-title {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      span:last-child {
        color: #84818A;
      }
    }

    .g-interaction-edit-insights-card-title-footer-text {
      font-weight: 600;
      line-height: 20px;
      color: #84818A;
    }
  }

  .g-interaction-edit-insights-card-filter-selector-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .g-interaction-edit-insights-card-filter-selector {
      width: 125px;
      font-size: 14px;
    }
  }
}