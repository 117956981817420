.g-instagram-modal-card {
  display: grid;
  grid-template-columns: auto 25px;
  grid-template-rows: 285px 20px;
  height: 315px;
  width: 165px;
  border-radius: 2px;
  border: 3px solid transparent;

  .g-instagram-modal-card-video-loader {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  .g-instagram-modal-card-video-wrapper {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
    video {
      border-radius: 2px;
    }
  }

  .g-instagram-modal-card-select-checkbox-wrapper {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
    margin-top: 5px;

    .g-instagram-modal-card-select-checkbox {
      width: 16px;
      height: 16px;
    }
  }

  .g-instagram-modal-card-caption-wrapper {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    cursor: pointer;
  }
}

.g-instagram-modal-card-selected {
  border: 3px solid var(--primary);
}