.g-customization-color-target-select {
  width: 100%
}

.g-customization-color-rgba-selector-container {
  .g-customization-color-rgba-selector {
      position: relative;
      width: 100%;

      .react-colorful__pointer {
          height: 32px;
          width: 32px;
          border: 7px solid #fff;
          box-shadow: 0 0 0 1px var(--borderLightColor);
      }

      .react-colorful__saturation {
          border-radius: 8px;
      }

      .react-colorful__hue {
          height: 16px;
          margin-top: 15px;
          margin-left: 65px;
          margin-right: 10px;
          border-radius: 24px;
      }

      .react-colorful__alpha {
          height: 16px;
          margin-top: 21px;
          margin-left: 65px;
          margin-right: 10px;
          border-radius: 18px;
      }

      &.g-customization-color-rgba-selector-short {
          margin-bottom: 16px;

          .react-colorful__hue {
              margin-left: 10px;
          }

          .react-colorful__alpha {
              margin-left: 10px;
          }
      }
  }
}