.g-interaction-edit-question-edit-add-to-cart-choice-container {
  display: flex;
  flex-direction: column;

  .g-interaction-edit-question-edit-add-to-cart-color-choices-container {
    display: flex;
    gap: 15px;

    .g-interaction-edit-question-edit-add-to-cart-color-choice-container {
      flex: 1;
    }
  }
}