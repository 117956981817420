.g-interaction-edit-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .g-interaction-edit-card-container {
    flex: 1;
    box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14), 0px 23px 44px rgba(176, 183, 195, 0.14);
    border-radius: 8px;
    height: 100%;

    .ant-card {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }

    .ant-card-body {
      padding: 0px;
      height: 100%;
      border-radius: inherit;
    }

    .ant-card-head-title {
      padding: 0px;
    }

    .g-interactions-settings-card-body-container {
      display: grid;
      grid-template-columns: 0.61fr 1fr 0.57fr;
      grid-template-rows: auto;
      height: 100%;


      .ant-card-grid {
        padding: 15px;
      }

      .g-interactions-settings-sub-card-container {
        display: flex;
        flex-direction: column;
        width: auto;
        overflow: hidden;

        .ant-list-item {
          border-bottom: none;
        }

        &.g-no-padding {
          padding: 0px;
        }
      }
    }
  }
}