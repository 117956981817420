@import 'assets/scss/mixing.scss';

.g-interaction-edit-question-list-container {
  flex-grow: 1;
  overflow-y: auto;
  height: 110px;
  max-height: calc(100% - 170px);
  @include custom-scrollbar;
}

.g-interaction-edit-question-list-empty-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 50px;
}