.g-interaction-edit-insights-card-summaries {
  display: flex;
  gap: 15px;
  justify-content: space-evenly;
  margin: 15px 0px;
  border-radius: 8px;

  .g-interaction-edit-insights-card-summary-card-container {
    flex: 1;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 23px 44px rgba(176, 183, 195, 0.14);
    border-radius: 8px;

    .g-interaction-edit-insights-card-summary-card {
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .g-interaction-edit-insights-card-summary-image-container {
        width: 42px;
        margin-bottom: 10px;

        .g-interaction-edit-insights-card-summary-image {
          width: 100%;
        }
      }

      .g-interaction-edit-insights-card-summary-title {
        color: #8F92A1;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.4px;
      }

      .g-interaction-edit-insights-card-summary-count {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.4px;
      }
    }
  }
}