.g-manage-content-pages-table-container {
    padding: 24px;
    border-radius: 8px;
    background-color: white;

    .ant-table-thead {
        .ant-table-cell {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #84818A;
        }
    }

    .g-dropdown-with-text {
        display: flex;
        align-items: center;
        cursor: pointer;


        .g-dropdown-circle-btn {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }
    }

    .g-list-page-table-title {
        padding-bottom: 26px;
        padding-top: 26px;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: -16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }

    .g-manage-content-spacer-action-items {

        .ant-btn {
            height: 28px;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            border: 1px solid #EFEFEF;
        }

        .g-manage-content-spacer-action-items-open-page-button {
            border: none;
            outline: none;
            margin-right: -7px;
        }

        .g-manage-content-spacer-action-items-edit-page-button {
            border: none;
            outline: none;
            margin-left: -7px;
        }

        .g-edit-page-table-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 26px;
            padding-bottom: 26px;

            .ant-form-item {
                margin-bottom: 0px;
                width: 300px;
            }
        }

        .g-manage-content-spacer-action-items-delete-button {
            display: flex;
            align-items: center;
            width: 32px;
        }
    }
}

.g-delete-page-confirmation-modal-container {
    .ant-modal-confirm-btns {
        display: flex;
        justify-content: flex-end;

        .ant-btn {
            display: flex;
        }
    }
}