.g-instagram-modal-step-two-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .g-instagram-modal-disclaimer {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 125%;
    text-align: center;
    color: #6D7175;
  }
  
  .g-instagram-modal-import-button {
    width: 365px;
    height: 44px;
    border-radius: 4px;
  }
}