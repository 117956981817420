.g-onboarding-modal {
    .ant-modal-content {
        padding: 30px 10px 0 10px;
    }

    .g-onboarding-modal-content {
        display: flex;
        align-items: center;
        flex-direction: column;

        .g-onboarding-modal-subtitle {
            max-width: 575px;
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;
            color: #6d7175;
        }

        .g-onboarding-modal-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: 20px;
            margin-top: 15px;
        }

        .g-onboarding-modal-select-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 40px;

            .g-onboarding-modal-select-node {
                display: flex;
                max-width: 510px;
                height: 120px;

                .g-onboarding-modal-select-node-logo {
                    height: 80px;
                    width: 80px;
                    min-width: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #f3f4f6;
                    border-radius: 24px;
                    margin-right: 24px;
                }

                .g-onboarding-modal-select-node-content {
                    display: flex;
                    flex-direction: column;

                    .g-onboarding-modal-select-node-title {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        color: #22272F;
                    }

                    .g-onboarding-modal-select-node-subtitle {
                        height: 56px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #454f5f;
                        margin-bottom: 5px;
                    }

                    .g-onboarding-modal-select-node-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        width: 122px;
                        height: 29px;
                        border-radius: 4px;

                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 700;
                        border: 1px solid #F2F2F2;
                        font-size: 12px;
                
                        &.ant-btn:hover,
                        &.ant-btn:focus,
                        &.ant-btn:active {
                            color: #fff;
                            border-color: #40a9ff;
                            background: #40a9ff;
                        }
                    }

                    .ant-btn {
                        padding-top: 4.01px !important;
                    }
                }
            }
        }
    }
}
