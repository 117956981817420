.g-interaction-edit-question-edit-form-field-multple-choice-grid {
  display: grid;
  grid-template-columns: auto 1fr 1fr auto;
}

.g-interaction-edit-question-edit-form-field-picture-choice-grid {
  display: grid;
  grid-template-columns: auto 120px 1fr auto;
}

.g-interaction-edit-question-edit-form-field-choice-container {
  gap: 10px;
  justify-content: center;
  align-items: start;
  margin-bottom: 24px;


  .g-interaction-edit-question-edit-form-field-choice-icon {
    align-self: center;
  }

  .g-interaction-edit-question-edit-form-field-choice {
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
  }

  .g-interaction-edit-question-edit-form-field-choices-select-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    overflow: hidden;
    margin-bottom: 0px;
  }

  .g-interaction-edit-question-edit-form-field-choice-input {
    height: 40px;
  }

  .g-interaction-edit-question-edit-form-field-choice-delete-container {
    align-self: center;
    padding-right: 5px;
  }
}