@import 'assets/scss/mixing.scss';

.g-header-right {
    display: flex;
    align-items: center;
    padding-right: 18px;
    width: auto;
    overflow: hidden;

    @include media-breakpoint-up-md {
        padding-right: 34px;
    }


    .g-header-menu-dropdown {
        display: flex;
        cursor: pointer;
        width: 100%;
        justify-content: flex-end;
        .g-header-right-user-avatar {
            display: flex;
            height: 36px;
            width: 36px;

            img {
                border-radius: 50%;
            }
        }

        .g-header-right-user-detail-container {
            height: 36px;
            padding-left: 8px;
            width: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .g-header-right-user-name {
                font-family: "Manrope", sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 19.12px;
                height: 19px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .g-header-right-user-type {
                font-family: "DM Sans", sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 15.62px;
                height: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .g-header-right-dropdown {
            display: flex;
            align-items: flex-start;
            padding-left: 5px;
            padding-top: 2px;

            svg {
                fill: red;
            }
        }
    }
}