.g-interaction-edit-results-card-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;

  .g-interaction-edit-results-card-toggle-buttons-container {
    display: flex;
    gap: 10px;
    height: inherit;
    align-items: center;

    .g-interaction-edit-results-card-toggle-button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.05em;
      height: inherit;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px;

      &.ant-btn-text {
        color: rgba(28, 46, 69, 0.6);
      }

      &:hover {
        color: #2F2F2F;
        border-bottom: 2px solid #2F2F2F;
      }
    }

    .g-interaction-edit-results-card-toggle-button-active {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.05em;
      height: inherit;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px;
      color: #2F2F2F;
      border-bottom: 2px solid #2F2F2F;
    }
  }
}