.g-manage-content-video-table-container {
    padding: 24px;
    border-radius: 8px;
    background-color: white;

    .g-dropdown-with-text {
        display: flex;
        align-items: center;
        cursor: pointer;


        .g-dropdown-circle-btn {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }
    }

    .g-manage-content-spacer-action-items {
        .ant-btn {
            height: 28px;
            font-size: 12px;
            border: 1px solid #EFEFEF;
            font-weight: 600;
            line-height: 20px;
            box-shadow: none;
        }

        .g-manage-content-spacer-action-items-delete-button {
            display: flex;
            align-items: center;
        }
    }

    //columns file styles here
    .g-list-video-container {
        display: flex;
        align-items: center;

        .g-list-video-player {
            height: 52px;
            width: 52px;
            margin: 10px 16px 10px 0px;

            .g-video-not-complete-container {
                font-size: 10px;
            }

            .g-video-preview-thumbnail {
                border-radius: 4px;
            }
        }

        .g-edit-video-table-title-button {
            border: none;
            outline: none;

            //In-line with video title
            margin-left: 7px;
            margin-right: 25px;
            margin-bottom: 30px;

        }

        .g-list-video-table-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 300px;
        }

        .g-edit-video-table-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 12px;
            margin-bottom: -13px;

            .ant-form-item {
                width: 300px;
            }
        }

        .g-list-video-details {
            display: flex;

            div {
                margin: 0 4px;
            }

            .g-list-video-details-dot {
                background-color: #84818A;
                border-radius: 50%;
                height: 4px;
                width: 4px;
                margin: auto 5px;
            }
        }
    }


    .g-list-date-uploaded-container {

        .g-list-date-uploaded-date {
            font-weight: 600;
        }

        .g-list-date-uploaded-time {
            font-weight: 500;
            color: #84818A;
        }
    }

    .g-list-sources-container {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        .g-list-source-tag-loading {
            width: 90px;
            height: 20px;
        }
    }

}

.g-manage-content-video-delete-confirmation-modal {
    .ant-modal-confirm-btns {
        display: flex;
        justify-content: flex-end;

        .ant-btn {
            display: flex;
        }
    }
}

.g-manage-content-video-list-linked-pages-container {
    width: 100%;

    a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .g-video-list-page-link-text {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.g-list-sources-popover-content {
    display: flex;
    flex-direction: column;

    .g-list-sources-popover-content-title {
        padding: 0 0 6px 0;
        margin-bottom: 10px;
        font-size: 16px;
        border-bottom: 0.5px solid gainsboro;
    }
}