.g-customization-layout-button,
.g-customization-layout-button-selected {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 150px;
  border: 1px solid var(--borderLightColor);
  border-radius: 4px;

  &.disabled {
    border: 1px solid var(--borderLightColor);
    border-radius: 4px;
    cursor: not-allowed;

    .g-customization-layout-button-text {
      opacity: 0.5;
    }

    .g-customization-layout-button-icon {
      opacity: 0.5;
    }

    &:hover {
      .g-customization-layout-button-text {
        opacity: 0.1;
      }
  
      .g-customization-layout-button-icon {
        opacity: 0.1;
      }

      .g-customization-layout-button-tooltip {
        visibility: visible;
      }
    }
  }

  .g-customization-layout-button-icon {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 100%;
  }

  .g-customization-layout-button-text {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    align-items: center;
    height: 100%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
  }

  .g-customization-layout-button-tooltip {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: black;
    opacity: 0.8;
    font-family: 'Manrope';
    font-style: normal;
    white-space: normal;
  }
}

.g-customization-layout-button-selected {
  background: rgba(60, 142, 249, 0.1);
  border: 2px solid #3C8EF9;
}