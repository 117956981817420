@import 'assets/css/antd.css';
@import 'assets/scss/variables.scss';
@import 'assets/scss/mixing.scss';

html {
    zoom: 1;

    @include media-breakpoint-up-lg {
        zoom: 0.8;
    }

    @include gander-media-breakpoint-up-1450 {
        zoom: 1;
    }
}

body {
    margin: 0;
    // font-family: 'Monolope', 'DM Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //     sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // font-size: 14px;
    overflow: hidden;
    height: 100%;

    #root {
        height: 100%;
    }
}

.g-no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.g-custom-search-select {
    .ant-select-selection-item {
        color: lightgray;
    }
}

.g-list-with-video-previews {
    width: 290px;
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 4px;

    &.g-list-with-video-previews-fixed-height {
        max-height: 40vh;
        overflow: auto;
        @include custom-scrollbar;
    }

    .g-list-with-video-preview-title-description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ant-list-item {
        padding: 15px 15px;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    .ant-list-item-meta-avatar {
        margin: 0;
        cursor: pointer;
    }

    .g-list-with-video-preview-avatar {
        display: flex;
        position: relative;
        margin-right: 14px;
        align-items: center;

        width: 52px;
        min-width: 52px;
        min-height: 52px;
        height: 52px;

        .g-video-preview-thumbnail {
            border-radius: 4px;
        }

        .g-video-not-complete-container {
            font-size: 10px;

            .g-blinking-message {
                margin: 0;
            }
        }
    }
}

.g-table-default-padding {
    .ant-table-cell {
        padding: 11px;
    }
}

.g-toggle-arrow-with-background {
    background: white;
    border-radius: 50%;
    box-shadow: 0px 55.3704px 105.926px rgba(176, 183, 195, 1.14);
    border: '1px solid #F7F7F7';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -35px;
    transition: background 0.3s ease;

    &:hover {
        background-color: #f0f0f0;
    }
}

.ant-table-cell::before {
    display: none;
}

// notification message styles override
.ant-notification-notice {
    width: 500px;
    padding: 10px;

    .ant-notification-notice-icon {
        font-size: 20px;
        height: 20px;
        width: 20px;

        .anticon {
            color: white;

            svg {
                height: 20px;
                width: 20px;
            }
        }
    }

    .ant-notification-notice-message {
        color: white;
        font-size: 16px;
        font-weight: 600;
        margin-left: 40px;
        margin-right: 5px;
    }

    .ant-notification-notice-close {
        top: 13px;
        color: white;
    }
}

.ant-notification-notice.g-toast-info {
    background-color: var(--primary);
}

.ant-notification-notice.g-toast-error {
    background-color: red;
}

.ant-notification-notice.g-toast-success {
    background-color: green;
}

.ant-notification-notice.g-toast-warning {
    background-color: goldenrod;
}


//  common classes
.g-description-text {
    color: #84818A;
}

.g-primary-description-text {
    color: var(--primary);
}

.g-padding-0 {
    padding: 0;
}

.g-padding-30 {
    padding: 30px;
}

.g-padding-x-20 {
    padding: 20px 0;
}

.g-padding-y-20 {
    padding: 0 20px;
}

.g-padding-y-1 {
    padding: 0 1px;
}

.g-d-flex {
    display: flex;
}

.g-align-center {
    align-items: center;
}

.g-justify-between {
    justify-content: space-between;
}

.g-justify-center {
    justify-content: center;
}

.g-justify-end {
    justify-content: flex-end;
}

.g-w-100 {
    width: 100%;
}

.g-h-100 {
    height: 100%;
}

.g-m-0 {
    margin: 0;
}

.g-mx-4 {
    margin-left: 4px;
    margin-right: 4px;
}

.g-mx-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.g-my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.g-my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.g-my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.g-mx-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.g-ml-8 {
    margin-left: 8px;
}

.g-ml-16 {
    margin-left: 16px;
}

.g-mr-8 {
    margin-right: 8px;
}

.g-mb-8 {
    margin-bottom: 8px;
}

.g-mb-16 {
    margin-bottom: 16px;
}

.g-mt-4 {
    margin-top: 4px;
}

.g-mt-8 {
    margin-top: 8px;
}

.g-mt-16 {
    margin-top: 16px;
}

.g-mt-24 {
    margin-top: 24px;
}

.g-float-right {
    float: right;
}

.g-float-left {
    float: left;
}

.g-title-level-6 {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
}

// g-div-flash starts
// NOTE: this will allow us to flash/highlight div with css
.g-div-flash {
    scroll-margin: 2rem;

    &:target {
        animation: yellowflash-outline 2s;
    }

    & :target {
        animation: yellowflash-bg 2s;
    }
}

@keyframes yellowflash-bg {
    from {
        background: yellow;
    }

    to {
        background: transparent;
    }
}

@keyframes yellowflash-outline {
    from {
        outline: 10px solid yellow;
    }

    to {
        outline: 10px solid transparent;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// g-div-flash ends

// solving scroll issue for ant tables globally
.ant-table-content {
    @include custom-scrollbar;
    width: 100%;
    overflow-y: auto; // we can allow this component wise if its required default:auto
}