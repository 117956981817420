.g-interation-edit-add-question-container {
  display: flex;
  justify-content: center;
  padding: 12px 10px 4px 10px;

  .g-interation-edit-add-question-button {
    flex: 1;
    min-width: 260px;
    height: 50px;
    background-color: var(--primary);
  }

  .g-interation-edit-add-question-button-disabled {
    flex: 1;
    min-width: 260px;
    height: 50px;
  }

  .g-interation-edit-add-question-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: white;
  }
}

.g-interaction-edit-add-question-select-title {
  padding: 5px;
  font-weight: 600;
  text-align: center;
}