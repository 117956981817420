.g-interaction-edit-responses-card-table-header-row {
  display: flex;
  align-items: center;
  height: 65px;
  color: #84818A;
}

.g-interaction-edit-responses-card-table-cell {
  width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.g-interaction-edit-responses-card-table-cell:hover {
  display: block;
  overflow: visible;
  white-space: normal;
  height: auto;
}