@import 'assets/scss/mixing.scss';

.g-interaction-edit-preview-panel-add-to-cart-interaction-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    .g-interaction-product-details {
        position: absolute;
        background: white;
        width: 100%;
        bottom: 0;
        left: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        max-height: 200px;
        min-height: 100px;
        z-index: -1;
        overflow: hidden;

        &.active {
            transition: all 0 ease-in-out;
            opacity: 1;
            z-index: 0;
        }

        .g-interaction-product-details-close {
            position: absolute;
            right: 7px;

            img {
                height: 10px;
                width: 10px;
            }
        }

        .g-interaction-product-details-price-img {
            padding: 14px;
            display: flex;
            align-items: center;

            img {
                height: 50px;
                width: 50px;
                border-radius: 4px;
                margin-right: 4px;
            }

            .g-interaction-product-price-div {
                display: flex;
                flex-direction: column;
                flex: 1;

                .g-interaction-product-details-title-span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 150px;
                    font-weight: 600;

                }

                .g-interaction-product-details-price-span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 90%;
                    font-weight: 700;
                    color: #737373;
                }

                .g-interaction-product-details-compare-price-span {
                    font-weight: 400;
                    color: #737373;
                    margin-left: 10px;
                    text-decoration: line-through
                }
            }
        }

        .g-interaction-product-details-variant-dropdowns {
            padding: 0px 7px;
            overflow: auto;
            max-height: 77px;
            @include custom-scrollbar;

            select {
                width: 100%;
                border: 0.55px solid lightgray;
                border-radius: 2px;
                height: 30px;
                margin-bottom: 7px;
            }
        }
    }

    .g-interaction-edit-preview-panel-add-to-cart-interaction-button-container {
        margin: 10px 7px;
        display: flex;
        justify-content: center;
        height: 28px;
        border-radius: 4px;
        cursor: pointer;

        .g-interaction-edit-preview-panel-add-to-cart-interaction-button {
            display: flex;
            justify-content: center;

            .g-interaction-edit-preview-panel-add-to-cart-interaction-text {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 12px;
            }
        }
    }
}