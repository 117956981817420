@import "assets/scss/mixing.scss";

.g-preview-state-page-settings-container {
    height: 100%;
    min-width: 300px;
    background: #ffffff;
    border-bottom: 1px solid rgba(176, 183, 195, 0.14);
    border-right: 1px solid rgba(176, 183, 195, 0.14);
    border-left: 1px solid rgba(176, 183, 195, 0.14);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: auto;
    overflow: auto;
    @include custom-scrollbar;
    @include custom-scrollbar;

    .ant-collapse-item {
        border-bottom: 1px solid #d9d9d9;

        .ant-collapse-header[aria-expanded="true"] {
            background: #3C8EF91A;
            border: 1.5px solid #3C8EF9;
        }
    }

    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: #40a9ff;
        border-color: #40a9ff;
    }

    .g-customization-setting-section-header {
        font-weight: 800;
    }

    .g-customization-settings-container {
        display: flex;
        flex-direction: column;
        // gap: 20px;
        // margin-bottom: 15px;

        .g-customization-field-header {
            font-size: 12px;
            font-weight: 650;
            padding-bottom: 10px;
        }

        .g-customization-field-header:not(:first-child) {
            padding-top: 15px;
        }

        .g-customization-input {
            border-radius: 8px;
            width: 100%;
        }

        .g-customization-toggle-buttons-container {
            display: flex;
            height: 40px;
            gap: 5px;

            .g-customization-toggle-button,
            .g-customization-toggle-button-selected {
                flex: 1;
                height: 100%;
                font-size: 10px;
                border: 1px solid var(--borderLightColor);
            }

            .g-customization-toggle-button-selected {
                background: rgba(60, 142, 249, 0.1);
                border: 2px solid #3C8EF9;
            }

            .g-customization-toggle-button-container {
                display: flex;
                flex-direction: column;
            }
        }

        .g-customization-slider-container {
            display: grid;
            grid-template-columns: 20% auto 20%;
            column-gap: 10px;
            align-items: center;

            .g-customization-slider-left-item,
            .g-customization-slider-right-item {
                color: #727279;
                font-size: 14px;
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.05px;
            }

            .g-customization-slider-left-item {
                justify-self: start;
            }

            .g-customization-slider-right-item {
                justify-self: end;
            }
        }

        .g-customization-checkbox-group {
            display: flex;
            flex-direction: column;
            margin-top: 15px;

            .ant-checkbox-wrapper {
                font-size: 12px;
                font-family: Manrope;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }
        }
    }


    .g-preview-state-settings-loader {
        display: flex;
        height: calc(100% - 70px);
        justify-content: center;
        align-items: center;
    }
}