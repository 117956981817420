@import 'assets/scss/mixing.scss';

.g-settings-page-container {
    width:100%;

    .g-setting-tabs-container {
        background: #FFFFFF;
        box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
        border-radius: 8px;
        height: 100%;

        .ant-tabs-nav {
            padding: 12px 35px 0 35px;

            .ant-tabs-tab-btn {
                color: #3C8EF9;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .ant-tabs-content-holder {
            padding: 0 35px 10px 35px;
            overflow: auto;
            @include custom-scrollbar;
        }
    }
}