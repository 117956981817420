@import 'assets/scss/mixing.scss';

.ant-select-dropdown .ant-select-item-option .g-ant-select-item-a {
    a {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
}

.g-dashboard-component {
    margin: 26px 0 30px 0;

    .ant-space {
        display: flex;


        .ant-page-header {
            padding: 0 0 0 0;
        }

        .ant-page-header-heading-extra .g-header-extra {

            .ant-select {
                width: 250px;
                margin-right: 15px;
            }

            .ant-typography {
                margin-top: 19.2px;
                margin-left: auto;
                margin-right: 0px;
            }
        }

    }

    .ant-card-head {
        border: unset;

        .ant-card-head-title {
            .ant-card-meta {
                .ant-card-meta-title {
                    @include media-breakpoint-up-lg {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .ant-card-body {
        padding-top: 0px;
    }

    .g-metrics-component {
        margin-left: 45px;

        .g-metrics-component-title-no-data {
            color: gray;
        }

        .g-metrics-component-col-flex {
            justify-content: flex-end;
            display: flex;
        }

        .g-metrics-component-blocked-button {
            font-weight: bold;
            cursor: default;
        }

        .g-metrics-component-blocked-col-flex {
            justify-content: flex-end;
            display: flex;
            align-content: center;

            a {
                color: black;
            }
        }

        .ant-avatar {
            height: 24px;
            width: 24px;
            line-height: 22px;

            @include media-breakpoint-up-lg {
                height: 20px;
                width: 20px;
                line-height: 20px;
                font-size: 14px;
            }
        }

        .g-metrics-component-text {
            .ant-col {
                justify-content: flex-end;
                display: flex;
                align-content: center;
            }

            .ant-typography {
                margin-top: 3px;
            }
        }

        .ant-statistic {

            .ant-statistic-content-value,
            .ant-statistic-content-suffix {

                font-size: 12px;

                @include media-breakpoint-up-md {
                    font-size: 14px;
                }
            }
        }
    }

    #statistic-blank {
        color: white;
    }

    .g-viz-component {
        height: 100%;

        .g-device-breakdown {
            .ant-statistic-content-value {
                color: white;
            }

            .ant-avatar-circle {
                margin-right: 6px;
            }

            .g-device-breakdown-text {
                color: #84818A
            }

            .g-device-breakdown-padding {
                padding-left: 12px
            }
        }
    }

    .g-video-analytics-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .g-video-analytics-title-container {
            display: flex;
            align-items: center;
        }

        .g-video-analytics-info-container {
            font-size: 14px;

            .ant-typography {
                color: #84818A;
            }

            .ant-statistic {
                .ant-statistic-content {
                    font-size: 14px;
                    margin-bottom: 2px;
                }
            }
        }
    }

    .g-video-analytics-card {
        .ant-result-icon .anticon {
            color: #808080;
        }

        .ant-card-head-wrapper {
            .ant-card-head-title {
                .g-video-analytics-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .g-video-analytics-title-container {
                        display: flex;
                        align-items: center;
                    }

                    .g-video-analytics-info-container {
                        font-size: 14px;

                        .ant-typography {
                            color: #84818A;
                        }

                        .ant-statistic {
                            .ant-statistic-content {
                                font-size: 14px;
                                margin-bottom: 2px;
                            }
                        }
                    }
                }

                .ant-typography {
                    margin-bottom: 0;
                    margin-right: 15px;
                }
            }

            .ant-card-extra {
                margin-left: 15px;
            }
        }
    }

    .g-metrics-table-analytics-card {
        height: 100%;

        .ant-card-head-wrapper {
            .ant-card-head-title {
                .g-metrics-table-analytics-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .g-metrics-table-analytics-title-container {
                        display: flex;
                        align-items: center;
                    }

                }

                .ant-typography {
                    margin-bottom: 0;
                    margin-right: 15px;
                }

                .g-metrics-table-analytics-info-container {
                    display: inline;

                    .g-metric-card-title {
                        font-weight: '700'
                    }
                }
            }

            .ant-card-extra {
                margin-left: 15px;
            }
        }
    }

    .g-dashboard-state-container {
        margin: 5px 0;

        .ant-card {
            height: 100%;
        }

        .g-device-breakdown {
            .ant-statistic-content-value {
                color: white;
            }
        }

        .ant-card .ant-card-head .ant-card-head-title .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
            padding-top: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }


        &.small {
            .ant-card {
                height: 100%;

                .ant-card-head {
                    .ant-card-extra {
                        padding-bottom: 24px;
                    }
                }
            }
        }

        .g-metric-viz-body {
            height: "100%";
            vertical-align: "center"
        }

        .g-metrics-table-component {
            .ant-card-head-wrapper {
                .ant-card-head-title {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .g-dashboard-performance-gauge {
        height: 460px
    }


    .g-metric-card-table-blocked-div {
        background: "#3C8EF9";
        border-radius: "5px";
        padding: "30px";
        position: 'relative';
        overflow: 'hidden';

        .g-metric-card-table-blocked-title {
            margin-top: 10px;
            margin-bottom: 10px;
            color: white;
            z-index: 2;
            position: relative;
        }

        .g-metric-card-table-button {
            background: "white";
            font-weight: "bold";
            color: "#3C8EF9";
            z-index: '2'
        }

        .g-metric-card-table-upgrade-button {
            background: white;
            font-weight: bold;
            color: #2F2F2F;
            width: 300px;
            height: 50px;
            z-index: 2;
        }

        .g-metric-card-table-empty-div {
            height: '220px';
            width: '220px';
            border-radius: '50%';
            background: '#FFFFFF';
            opacity: '0.1';
            position: 'absolute';
            bottom: '-120px';
            left: '-80px';
            z-index: '1'
        }

        .g-metric-card-table-floating-div {
            height: '170px';
            width: '170px';
            border-radius: '50%';
            position: 'absolute';
            top: '10px';
            right: '10px';
            z-index: '1'

            Icon {
                z-index: 1;
            }
        }
    }
}