@import 'assets/scss/mixing.scss';

.g-site-fullscreen-layout-background {
    z-index: 1011;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .g-header-left-title {
        font-family: "Manrope", sans-serif;
        font-weight: 600;
        margin: 0px 18px;
        font-size: 14px;
        line-height: 32.78px;
        color: #020202;
        flex: 0 0 40%;

        @include media-breakpoint-up-md {
            font-size: 24px;
            margin: 22px 29px;
        }
    }
}