.g-add-edit-page-details-link-page-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .g-add-edit-page-details-spinner-container {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}


.g-add-edit-page-details-items-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
}

.g-add-edit-page-details-no-found-content {
  text-align-last: center;
}

.g-add-edit-page-details-no-page-divider {
  margin: 1px;
}