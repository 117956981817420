@import 'assets/scss/mixing.scss';

.g-manage-content-interaction-details-drawer-title-container {

    .g-manage-content-interaction-details-drawer-title-input {
        margin-bottom: 10px;
        font-size: 20px;
        width: 350px;
    }

    .g-manage-content-interaction-details-drawer-title {
        max-width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .g-manage-content-interaction-details-drawer-title-edit-icon {
        margin: 0 0 15px 15px;

        span {
            height: 100%;
            width: 23px;
            cursor: pointer;
        }
    }
}
.g-manage-content-interaction-details-drawer {
    .ant-drawer-body {
        padding: 30px;
    }

    .g-manage-content-interaction-details-drawer-title {
        max-width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .g-interaction-details-drawer-content-container {
        padding: 0px 6px;
        height: 100%;

        .g-interaction-details-form {
            padding: 0 30px 0 0;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .g-form-item-url {
                label {
                    width: 100%;

                    .ant-typography {
                        margin: 0;
                    }
                }
                .ant-input-group-addon {
                    text-overflow: ellipsis;
                    max-width: 170px;
                    overflow: hidden;
                }
            }
        }

        .g-interaction-details-connected-video-section {
            flex: 1;

            .g-list-header {
                display: flex;
                width: 100%;
                justify-content: space-between;
                text-transform: uppercase;
                padding: 13px 20px;
                background-color: #efefef;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                font-family: DM Sans;
                font-weight: 700;
                color: #84818A;
            }

            .g-interaction-details-no-video-list {
                padding: 25px 0 15px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #f0f0f0;
                border-top: unset;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .g-interaction-details-list {
                @include custom-scrollbar;
                margin: 0 0 20px 0;
                overflow: overlay;
                height: calc(100% - 205px);
            }
        }
    }

    .ant-drawer-footer {
        border: none;
        display: flex;
        justify-content: flex-end;
        margin: 20px 0px;
        padding: 0px 30px;
    }
}