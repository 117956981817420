.g-interaction-edit-add-question-select-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 8px;

  .g-interaction-edit-add-question-select-button {
    display: flex;
    justify-content: left;
    align-items: center;

    &:hover {
      background: var(--hover);
    }
  }
}