.g-video-upload-header {
    display: flex;
    justify-content: space-between;

    .g-video-upload-header-buttons-container {
        display: flex;
        gap: 10px;

        .g-video-upload-button {
            height: 38px;
            padding: 0 16px;
            margin: 0 0 0 5px;

            &.g-video-external-upload-button {
                padding: 0 8px;
            }

            .g-video-instagram-button-content,
            .g-video-tiktok-button-content {
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
            }

            .g-video-upload-button-content {
                font-family: "Manrope";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #2e2c34;
                height: 20px;
                display: flex;
                align-items: center;

                .g-video-upload-button-content-tooltip {
                    margin-left: 6px;

                    svg {
                        color: #84818A;
                        height: 12px;
                        width: 12px;
                    }
                }

                .g-video-upload-button-content-drop-icon {
                    margin-left: 42px;
                    height: 16px;
                    width: 16px;
                    color: #9ea3ae;
                    display: inline-flex;
                }
            }

            .g-video-instagram-icon,
            .g-video-tiktok-icon {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.g-generate-external-upload-confirmation-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .g-generate-external-upload-confirmation-modal-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        color: #2e2c34;
        margin: 12px 0 22px 0;
    }

    .g-generate-external-upload-confirmation-modal-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #6d7175;
        margin-bottom: 32px;
    }

    .g-generate-external-upload-confirmation-modal-button-container {
        display: flex;
        gap: 21px;

        .g-generate-external-upload-confirmation-modal-button {
            height: 44px;
            width: 150px;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }
    }
}