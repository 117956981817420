.g-interaction-edit-insights-card-analysis-card-container {
  margin-top: 5px;
  min-height: 400px;
  border-radius: 8px;

  .g-interaction-edit-insights-card-analysis-card {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 40px auto;
    padding: 35px;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 23px 44px rgba(176, 183, 195, 0.14);
    border-radius: 8px;

    .g-interaction-edit-insights-card-analysis-card-header {
      display: flex;
      justify-content: space-between;

      .g-interaction-edit-insights-card-analysis-card-title-container {
        flex: 1;
        display: flex;
        flex-direction: column;

        .g-interaction-edit-insights-card-analysis-card-title {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: #2E2C34;
          margin-bottom: 5px;
        }

        .g-interaction-edit-insights-card-analysis-card-sub-title {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #84818A;
        }
      }

      .g-interaction-edit-insights-card-analysis-card-legend {
        display: flex;
        align-items: flex-end;
        gap: 25px;

        .g-interaction-edit-insights-card-analysis-card-legend-item {
          display: flex;
          align-items: center;
          gap: 10px;

          .g-interaction-edit-insights-card-analysis-card-legend-views-icon {
            width: 12px;
            height: 12px;
            background: #EAEAEA;
          }

          .g-interaction-edit-insights-card-analysis-card-legend-submitted-icon {
            width: 12px;
            height: 12px;
            background: var(--primary);
          }
        }
      }
    }

    .g-interaction-edit-insights-card-analysis-card-body {
      .g-interaction-edit-insights-card-analysis-card-question-container {
        margin-top: 25px;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 22px 22px;
        grid-row-gap: 9px;

        .g-interaction-edit-insights-card-analysis-card-question-header {
          display: flex;
          justify-content: space-between;

          .g-interaction-edit-insights-card-analysis-card-question-title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
          }

          .g-interaction-edit-insights-card-analysis-card-question-response-count {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #84818A;
          }

          .g-interaction-edit-insights-card-analysis-card-response-container {
            display: flex;
            // align-items: center;
            gap: 7px;

            .g-interaction-edit-insights-card-analysis-card-question-response-count {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #84818A;
            }

            .g-interaction-edit-insights-card-analysis-card-image-container {
              height: 16px;

              .g-interaction-edit-insights-card-analysis-card-image {
                height: 100%;
              }
            }
          }
        }

        .g-interaction-edit-insights-card-analysis-card-question-views {
          grid-column-start: 1;
          grid-row-start: 2;
          background: #EAEAEA;
          border-radius: 4px;
        }

        .g-interaction-edit-insights-card-analysis-card-question-submitted {
          grid-column-start: 1;
          grid-row-start: 2;
          background: var(--primary);
          border-radius: 4px;
        }
      }
    }
  }
}