 .g-review-video-popup-wrapper {
     position: fixed;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     background: rgba(0, 0, 0, .4);
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     height: 100%;
     z-index: 10000;

     .g-review-video-popup-video-player-container {
         position: absolute;
         height: 60%;
         width: auto;
         display: flex;
         justify-content: center;
         align-items: center;
         background-color: black;
         border-radius: 5px;

         .g-review-video-popup-video-player-close-icon {
             position: absolute;
             z-index: 10000;
             bottom: calc(100% - 20px);
             left: calc(100% - 22px);
             padding: 5px;
             height: 40px;
             width: 40px;

             svg {
                 padding: 7px;
                 background: black;
                 fill: white;
                 border-radius: 50%;
                 height: 33px;
                 width: 33px;
             }
         }

         .g-review-video-popup-video-player-play-icon {
             position: absolute;
             z-index: 10000;
             transform: translate(-50%, -50%);
             top: 50%;
             left: 50%;

             &.g-hidden {
                 display: none;
                 pointer-events: none;
             }

             svg {
                 padding: 1px;
                 height: 50px;
                 width: 50px;
             }
         }

         .g-review-video-popup-footer-btns {
             background: transparent;
             padding: 10px 0px;
             display: flex;
             justify-content: space-between;
             position: absolute;
             z-index: 10000;
             bottom: -64px;
             right: 0;
             width: 100%;
         }
     }
 }