.g-confirmation-code-modal-container {
    .g-confirmation-code-modal-text {
        margin-bottom: 20px;
    }

    .g-confirmation-code-modal-footer-reset-btn,
    .g-confirmation-code-modal-footer-submit-btn {
        font-weight: bold;
    }

    .g-confirmation-code-modal-footer-reset-btn {
        color: black;
    }

    .g-confirmation-code-modal-footer-submit-btn {
        margin-right: 8px;
    }
}