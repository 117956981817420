
.g-public-external-video-upload-header {
    width: 100%;
    height: 85px;
    background: #1C1F4B;
    box-shadow: inset 0px -1px 0px #DFE3E8;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 123123123;

    .g-public-external-video-upload-header-left {
        display: flex;
        justify-content: center;
        align-items: center;

        .g-public-external-video-upload-header-left-logo {
            height: 54px;
            width: 150px;
        }
    }

    .g-public-external-video-upload-header-right {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 34px;

        .g-public-external-video-upload-header-user-info {
            display: flex;

            .g-public-external-video-upload-header-right-user-avatar {
                display: flex;
                height: 36px;
                width: 36px;

                img {
                    border-radius: 50%;
                }
            }

            .g-public-external-video-upload-header-right-user-detail-container {
                height: 36px;
                padding-left: 8px;
                color: #FFFFFF;
                display: flex;
                align-items: center;

                .g-public-external-video-upload-header-right-user-name {
                    font-family: "Manrope", sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19.12px;
                    height: 19px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}