.g-stripe-subscription-update-confirmation-modal {
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    // ant-d footer styles override
    .ant-modal-footer {
        padding: 22px 30px;
        display: flex;

        .ant-btn {
            height: 44px;
            width: 100%;
            margin: unset;
            font-family: "Manrope";
            font-style: normal;
            font-size: 16px;
            line-height: 20px;

            &.ant-btn-primary {
                margin-left: 20px;
            }
        }
    }
}
