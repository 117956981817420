// Large devices (desktops, 1450px and up)
@mixin gander-media-breakpoint-up-1450 {
  @media screen and (min-width: 1450px) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin gander-media-breakpoint-up-900 {
  @media screen and (min-width: 900px) {
    @content;
  }
}
@mixin media-breakpoint-up-lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin media-breakpoint-up-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

// Small devices (landscape phones, 576px and up)
@mixin media-breakpoint-up-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin custom-scrollbar {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}