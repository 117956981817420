.g-interactions-name-row-container {
  display: flex;
  gap: 10px;
  padding: 26px 0;

  .g-interactions-name-edit-submit-button {
    align-self: center;
  }
  .g-interaction-name-title {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}