@import 'assets/scss/mixing.scss';

.g-interation-edit-question-edit-title {
  padding: 20px 0px 10px 25px;
}

.g-interaction-edit-question-edit-form-card-container {
  flex: 1;
  padding: 20px;
  background: #F7F6F6;
  overflow: auto;

  .ant-card {
    border-radius: 7px;
  }

  .ant-form {
    height: inherit;
    padding: 20px 30px 25px 20px;
  }

  .g-interaction-edit-question-edit-form-fields-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;

    .g-interaction-edit-question-edit-form-fields {
      margin-bottom: 10px;
      @include custom-scrollbar;
      overflow-y: auto;
      height: calc(100% - 80px);

      .g-interaction-edit-question-edit-form-field-title-container {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        padding-bottom: 8px;
      }

      .g-interaction-edit-question-edit-form-field-input {
        height: 50px;

        .ant-select-selector {
          height: inherit;
          align-items: center;
        }
      }

      .g-interaction-edit-question-edit-form-field-radio-group {
        display: flex;
        gap: 30px;
        height: 50px;
        justify-content: center;

        .ant-radio-button-wrapper {
          border: none;

          &:before {
            display: none
          }

          .ant-radio-button-checked {
            background: #E6F1FE;
            border: 2px solid #3C8EF9;
            border-radius: 4px;
          }
        }

        .g-interaction-edit-question-edit-form-field-radio-button {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          flex: 1;
          max-width: 200px;
          height: inherit;
          border: 1px solid #E4E7EB;
        }
      }

      .g-interaction-edit-question-edit-form-divider {
        margin: 15px 0px;
      }
    }

    .g-interaction-edit-question-edit-form-field-submit-button {
      display: flex;
      justify-content: right;
    }
  }
}

.g-interaction-edit-question-edit-form-field-icon {
  color: #84818A;
}