.g-interactions-action-row-container {
  display: flex;
  gap: 10px;
  padding: 26px 0;

  .ant-btn {
    height: 28px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    border: 1px solid #EFEFEF;
    color: #2F2F2F;
  }

  .g-interactions-action-row-delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
  }
}