.g-some-license-purchase-confirmation-modal {

    // ant-d footer styles override
    .ant-modal-footer {
        padding: 22px 30px;
        display: flex;

        .ant-btn {
            height: 44px;
            width: 100%;
            margin: unset;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;

            &.ant-btn-primary {
                margin-left: 20px;
            }
        }
    }

    .ant-modal-body {
        padding: 0px;

        .g-some-license-purchase-confirmation-body {

            .g-some-license-purchase-confirmation-top {
                display: flex;
                border-bottom: 1px solid #EFEFEF;

                .g-some-license-purchase-confirmation-top-title-container {
                    padding: 24px 60px 24px 24px;

                    .g-some-license-purchase-confirmation-top-title {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 36px;
                    }

                    .g-some-license-purchase-confirmation-top-detail {
                        font-family: 'Inter';
                        margin-top: 8px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: #6D7175;
                    }
                }

                .g-some-license-purchase-confirmation-top-image-container {
                    padding-top: 16px;
                    width: 125px;

                    img {
                        width: 125px;
                        height: 126px;
                    }
                }

                .g-some-license-purchase-confirmation-top-close-container {
                    padding: 20px 20px 0 29px;
                    min-width: 50px;

                    div {
                        cursor: pointer;

                        svg {
                            float: right;
                        }
                    }
                }
            }

            .g-some-license-purchase-confirmation-main {
                padding: 22px 30px 31px 30px;

                .g-some-license-purchase-confirmation-main-detail {
                    width: 100%;

                    .g-some-license-purchase-confirmation-main-detail-primary {
                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                    }

                    .g-some-license-purchase-confirmation-main-detail-secondary {
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 150%;
                        color: #84818A;
                    }
                }
            }
        }
    }
}