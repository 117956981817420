.g-create-interactions-modal {
  min-width: 990px;

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  .ant-card-body {
    padding: 12px;
  }

  .g-create-interactions-body-container {
    flex-direction: row;
    gap: 20px;
    padding: 11px 54px 28px 54px;
  }

  .g-create-interactions-card-container {
    border: 1px solid rgba(143, 146, 161, 0.6);
    border-radius: 10px;
    width: 169px;

    .g-create-interactions-card-text-container {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      height: 170px;

      h4.ant-typography {
        font-weight: 700;
      }
    }

    .g-create-interactions-card-empty-image {
      width: calc(100%);
      min-width: 139px;
      height: 121px;
      background: #F0F0F0;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: var(--primary);
      margin-bottom: 5px;
    }
  }

  .g-create-interactions-card-container:hover {
    background-color: var(--hover);
    transition: all 0.3s ease;
    cursor: pointer;
  }
}