.g-interaction-edit-question-edit-form-field-picker {
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--hover);
}

.g-interaction-edit-question-edit-form-field-picker-popover {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .g-interaction-edit-question-edit-form-field-picker-popover-input {
    padding: 5px 10px;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    text-transform: uppercase;
    color: #2F2F2F;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-size: 14px;
    line-height: 150%;

    &:hover {
      border-color: var(--primary);
    }

    &:focus {
      border-color: purple;
    }
  }
}