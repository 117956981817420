.g-select-plan-table-billing-cycle-switch {
    display: inline-flex;
    border-radius: 9.961px;
    border: 1.245px solid #EBEBEB;
    background: #FEFEFE;
    margin-bottom: 10px;
    padding: 4px;

    .g-select-plan-table-billing-cycle-switch-button {
        margin: 4px;
        border-radius: 4.98px;
        font-family: Plus Jakarta Sans;
        font-size: 14.339px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        width: 116px;
        height: 51px;
        border-radius: 4.98px;
        background: #F6F6F6;
        color: var(--main-color-black, #000929);

        &.is-active {
            opacity: 1;
            border-radius: 4.98px;
            border: 1.868px solid var(--primary-blue, #3C8EF9);
            background: #E6F1FE;
            color: var(--primary-blue, #3C8EF9);
        }
    }
}