.g-interation-edit-preview-panel-multiple-choice-question-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .g-interation-edit-preview-panel-multiple-choice-question-text-container {
    background: rgba(0, 0, 0, 0.4);
    margin: 0px 10px;
    border-radius: 3px;

    .g-interation-edit-preview-panel-multiple-choice-question-text {
      color: #FFFFFF;
      padding: 5px;
      border-radius: 2px;
    }

    .g-preview-multiple-choice-interaction-question-text-default {
      font-size: 10px;
    }

    .g-preview-multiple-choice-interaction-question-text-large {
      font-size: 14px;
    }
  }

  .g-interation-edit-preview-panel-multiple-choice-question-response-container {
    display: flex;
    gap: 5px;

    .g-interation-edit-preview-panel-multiple-choice-question-response-choices {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      .g-interation-edit-preview-panel-multiple-choice-question-response-choice {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        color: white;
      }

      .g-preview-multiple-choice-interaction-response-choice-default {
        padding: 3px 4px;
        font-size: 9px;
      }

      .g-preview-multiple-choice-interaction-response-choice-large {
        padding: 4px 6px;
        font-size: 14px;
      }
    }

    .g-preview-multiple-choice-interaction-response-choices-default {
      gap: 3px;
    }

    .g-preview-multiple-choice-interaction-response-choices-large {
      gap: 5px;
    }

    .g-interation-edit-preview-panel-multiple-choice-question-next-button-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .g-interation-edit-preview-panel-multiple-choice-question-next-button {
        background: rgba(0, 0, 0, 0.4);
        text-align: center;
        border-radius: 2px;
        color: white;
        padding: 1px 4px;
      }

      .g-preview-multiple-choice-interaction-next-button-default {
        font-size: 11px;
      }

      .g-preview-multiple-choice-interaction-next-button-large {
        font-size: 19px;
      }
    }
  }

  .g-preview-multiple-choice-interaction-response-container-default {
    padding: 5px 10px 15px 10px;
  }

  .g-preview-multiple-choice-interaction-response-container-large {
    padding: 8px 10px 15px 10px;
  }
}