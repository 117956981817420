@import 'assets/scss/mixing.scss';

.g-app-layout {
    min-height: 100%;
    height: 100%;

    .g-trigger {
        z-index: 1002;
        padding: 0 14px;
        cursor: pointer;
        transition: all 0.3s ease;
        position: absolute;
        left: -26px;
        top: 6px;

        &:hover {
            color: #1890ff;
        }

        span {
            transition: all 0.5s ease-in;
        }
    }

    .g-logo {
        max-height: 81px;
        height: 81px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 6px;
        padding-bottom: 6px;

        img {
            width: 244px;
            height: 89px;
            max-width: 100%;
            animation: fadeIn 1.5s;
        }
    }

    .ant-layout-sider-collapsed {
        .g-logo {
            img {
                width: 60px;
                height: 60px;
            }
        }
    }

    .g-site-layout {
        height: 100%;
        .g-site-layout-background {
            position: relative;

            &.ant-layout-content {
                @include custom-scrollbar;
                overflow: auto;
                padding: 25px 30px 30px 30px;
                min-height: 280px;
                div.g-border-radius {
                    border-radius: 8px;
                }
            }
        }
    }
}