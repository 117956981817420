.g-instagram-modal-spinner {
  height: 343.1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.g-instagram-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .g-instagram-modal-title-icon {
    width: 50px;
    height: 56px;
    margin-bottom: 20px;
  }
}