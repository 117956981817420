@import 'assets/scss/mixing.scss';

.g-recently-added-videos-container {
    display: flex;
    overflow-x: auto;
    margin-bottom: 15px;

    @include custom-scrollbar;

    .g-recently-added-videos-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 263px;
        width: 100%;
        margin: 13px 0 15px 0;
    }

    .g-recently-added-video-card-container {
        width: 25%;
        min-width: 260px;
        max-width: calc(100% / 5);

        .g-recently-added-video-card {
            background: #F7F7F7;
            border: 1px solid #EDEDED;
            border-radius: 8px;
            margin: 13px 15px 15px 15px;
            position: relative;

            .g-recently-added-video {
                height: 180px;
                width: 75%;
                margin: auto;
                margin-top: 25px;
                margin-bottom: 57px;

                .g-video-preview-thumbnail {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
            }

            .ant-card-body {
                position: absolute;
                bottom: 0;
                background: white;
                width: 100%;
                padding: 9px 18px;

                .g-recently-added-video-card-body {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .ant-card-meta {
                        width: 80%;
                        
                        .ant-card-meta-title {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-weight: 600;
                            line-height: 20px;
                            color: #2E2C34;
                        }
    
                        .ant-card-meta-description {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 20px;
                            color: #84818A;
                        }
    
                        .g-dropdown-circle-btn {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }

                }
            }
        }
    }

    .g-recently-added-no-videos {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100%;
        margin: 13px 0 15px 0;
    }
}