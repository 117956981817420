@import 'assets/scss/mixing.scss';

.g-account-setup-billing-cycle-switch {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up-md {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: -58px;
        right: 0;
        margin-right: 46px;
    }

}

.g-select-plan-component {
    height: inherit;
    overflow: auto;
    margin-right: 2px;

    @include custom-scrollbar;

    @include media-breakpoint-up-md {
        padding: 31px 128px 0 163px;
    }

    .g-select-plan-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}