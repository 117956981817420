.g-interation-edit-preview-panel-short-answer-interaction-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .g-interation-edit-preview-panel-short-answer-question-text-container {
    background: rgba(0, 0, 0, 0.4);
    margin: 0px 10px;
    border-radius: 3px;

    .g-interation-edit-preview-panel-short-answer-question-text {
      color: #FFFFFF;
      padding: 5px;
      border-radius: 2px;
    }

    .g-preview-short-answer-interaction-question-text-default {
      font-size: 10px;
    }

    .g-preview-short-answer-interaction-question-text-large {
      font-size: 14px;
    }
  }

  .g-interation-edit-preview-panel-short-answer-question-response-container {
    display: grid;

    .g-interation-edit-preview-panel-short-answer-question-response-text-container {
      flex: 1;
      display: flex;
      align-items: center;
      border: 1px solid white;
      border-radius: 5px;
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.4);
      font-weight: 500;

      .g-interation-edit-preview-panel-short-answer-question-response-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .g-preview-short-answer-interaction-response-text-container-default {
      height: 25px;
      padding-left: 5px;
      font-size: 11px;
    }

    .g-preview-short-answer-interaction-response-text-container-large {
      height: 40px;
      padding-left: 5px;
      font-size: 16px;
    }

    .g-interation-edit-preview-panel-short-answer-question-response-button-container {
      display: flex;
      flex-direction: column;

      .g-interation-edit-preview-panel-short-answer-question-response-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
        text-align: center;
        border-radius: 2px;
        color: white;
        padding: 1px 4px;
      }

      .g-preview-short-answer-interaction-response-button-default {
        height: 25px;
        font-size: 11px;
      }

      .g-preview-short-answer-interaction-response-button-large {
        height: 40px;
        font-size: 19px;
      }
    }
  }

  .g-preview-short-answer-interaction-response-container-default {
    grid-template-columns: 180px auto;
    grid-gap: 5px;
    padding: 5px 10px;
  }

  .g-preview-short-answer-interaction-response-container-large {
    grid-template-columns: 264.5px auto;
    grid-gap: 10px;
    padding: 8px 10px;
  }

  .g-interation-edit-preview-panel-short-answer-question-footer-container {
    padding-left: 20px;
    padding-bottom: 10px;

    .g-interation-edit-preview-panel-short-answer-question-footer-text {
      color: #FFFFFF;
      text-decoration: underline;
    }

    .g-preview-short-answer-interaction-footer-text-default {
      font-size: 7px;
    }

    .g-preview-short-answer-interaction-footer-text-large {
      font-size: 8px;
    }
  }
}