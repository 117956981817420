.g-position-settings-container {
  display: flex;
  flex-direction: column;

  .g-customization-embedded-snippet-container {
    background: #FEFEFE;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 15px;
  
    .ant-typography {
      display: flex;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #84818A;
    }
  }
}