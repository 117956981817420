.g-pages-ab-test-drawer-header{
    display: flex;
    .g-ab-test-drawer-title {
        max-width: 275px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .g-ab-test-drawer-nav-arrows {
        margin-left: auto;
        padding-top: 15px;
    }
}
.g-manage-content-ab-test-drawer {
    .ant-drawer-content {
        padding: 6px;
    }

    .g-ab-test-drawer-content-container {
        padding: 0px 6px;

        .g-ab-test-drawer-form-table-div {
            margin-top: 20px;

            .g-list-with-video-previews {
                border: none;
                display: flex;
                background: unset;
                min-width: 220px;
                width: 255px;
                max-width: 255px;
            }
            tr>th.ant-table-cell  {
                text-transform: uppercase;
                background-color: #efefef;
                font-family: DM Sans;
                font-weight: 700;
                color: #84818A;
                &:first-child {
                    border-top-left-radius: 8px
                }
                &:nth-child(2) {
                    border-top-right-radius: 8px
                }
            }
        }
    }

    .ant-drawer-footer {
        border: none;
        display: flex;
        justify-content: flex-end;
        margin: 20px 0px;
        padding: 0px 30px;
    }
}