.g-add-edit-video-details-new-add-to-cart-button-card {
  margin-top: 15px;

  .g-add-edit-video-details-new-add-to-cart-button-form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .g-add-edit-video-details-new-add-to-cart-button-form-buttons-container {
      display: flex;
      justify-content: right;
      gap: 5px;
    }
  }
}