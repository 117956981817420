.g-interactions-page-header-right-container {
  display: flex;
  gap: 16px;
  height: 42px;

  .ant-btn {
      font-weight: 700;
      height: 42px;
  }

  .ant-input-affix-wrapper {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
      width: 262px;
      border: unset;
      height: 42px;
  }
}