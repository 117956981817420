.g-menu-group-text {
    font-family: "DM Sans";
    font-size: 12px;
    margin-bottom: 13px;
    color: rgb(132, 129, 138);
    padding: 6px 0px 0px 18px;
    margin-top: 8px;
}

.g-sidebar-menu {
    border: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;

    .ant-menu-item {
        padding-left: 30px !important;
        border-left: 3px solid transparent;

        .ant-menu-item-icon {
            display: flex;
            align-items: center;
            height: 100%;

            svg {
                min-width: 24px;
            }
        }
    }

    .ant-menu-item-selected {
        &::before {
            content: "";
            display: block;
            border-right: 3px solid var(--primary);
            position: absolute;
            border-radius: 0px 4px 4px 0px;
            top: 0;
            left: 0px;
            bottom: 0;
            height: 100%;
        }

        &::after {
            left: 14px;
            right: 20px;
            border-radius: 4px;
            background: #3c8ef91a;
            border: none;
        }
    }
}