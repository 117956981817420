.g-interation-edit-preview-panel-picture-choice-question-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .g-interation-edit-preview-panel-picture-choice-question-text-container {
    background: rgba(0, 0, 0, 0.4);
    margin: 0px 10px;

    .g-interation-edit-preview-panel-picture-choice-question-text {
      color: #FFFFFF;
      padding: 5px;
      border-radius: 2px;
    }

    .g-preview-picture-choice-interaction-question-text-default {
      font-size: 10px;
    }

    .g-preview-picture-choice-interaction-question-text-large {
      font-size: 14px;
    }
  }

  .g-interation-edit-preview-panel-picture-choice-question-response-container {
    display: flex;
    padding: 5px 10px 15px 10px;

    .g-interation-edit-preview-panel-picture-choice-question-response-choices {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 7px;

      .g-interation-edit-preview-panel-picture-choice-question-response-choice {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        color: white;
        font-size: 11px;


        .g-interation-edit-preview-panel-picture-choice-question-image-container {
          border-radius: 4px;

          .g-interation-edit-preview-panel-picture-choice-question-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
          }
        }

        .g-preview-picture-choice-interaction-image-container-default {
          width: 35px;
          height: 35px;
        }
  
        .g-preview-picture-choice-interaction-image-container-large {
          width: 50px;
          height: 50px;
        }
      }
    }

    .g-preview-picture-choice-interaction-response-choices-default {
      gap: 8px;
    }
  
    .g-preview-picture-choice-interaction-response-choices-large {
      gap: 15px;
    }

    .g-interation-edit-preview-panel--picture-choice-question-next-button-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .g-interation-edit-preview-panel--picture-choice-question-next-button {
        background: rgba(0, 0, 0, 0.4);
        text-align: center;
        border-radius: 2px;
        color: white;
        padding: 1px 4px;
      }

      .g-preview-picture-choice-interaction-next-button-default {
        font-size: 11px;
      }

      .g-preview-picture-choice-interaction-next-button-large {
        font-size: 19px;
      }
    }
  }

  .g-preview-picture-choice-interaction-response-container-default {
    padding: 5px 10px 15px 10px;
  }

  .g-preview-picture-choice-interaction-response-container-large {
    padding: 8px 10px 15px 10px;
  }
}