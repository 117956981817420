.g-video-preview-container {
    height: 100%;
    width: 100%;
    position: relative;

    .g-video-preview-thumbnail {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top center;
    }

    .g-video-preview-thumbnail-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.2));
    }
}

.g-react-video-player-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .4);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10000;

    .g-react-video-player-container {
        position: absolute;
        height: 80%;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        border-radius: 5px;

        .g-react-video-player-close-icon {
            position: absolute;
            z-index: 10000;
            bottom: calc(100% - 20px);
            left: calc(100% - 22px);
            padding: 5px;
            height: 40px;
            width: 40px;

            svg {
                padding: 7px;
                background: black;
                fill: white;
                border-radius: 50%;
                height: 33px;
                width: 33px;
            }
        }

        .g-react-video-player-play-icon {
            position: absolute;
            z-index: 10000;
            top: calc(50% - 40px);
            right: calc(50% - 25px);

            &.g-hidden {
                display: none;
                pointer-events: none;
            }

            svg {
                padding: 1px;
                height: 40px;
                width: 40px;
            }
        }
    }
}

.g-video-not-complete-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary);

    .g-video-progress-error-text {
        color: red;
    }
}