@import 'assets/scss/mixing.scss';

.g-upload-and-link-videos-form-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up-sm {
        height: calc(100% - 100px);
        overflow: auto;
        @include custom-scrollbar;
    }

    .g-upload-validation-error-text {
        color: red;
        font-size: 12px;
        height: 12px;

        &.g-upload-validation-error-text-video {
            text-align: center;
        }
    }

    .g-upload-and-link-videos-form {
        width: 100%;
        padding: 0 18px;
        background-color: white;
        box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up-sm {
            width: 714px;
            padding: 0;
            margin: 38px 0;
        }

        .g-public-upload-form-main-title {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            letter-spacing: -0.4px;
            color: #2E2C34;
            margin-top: 51px;
            width: 100%;

            @include media-breakpoint-up-sm {
                width: 352px;
            }
        }

        .g-public-upload-form-sub-title {
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: #000929;
            opacity: 0.5;
            margin-top: 12px;
            margin-bottom: 16px;
            width: 352px;

            @include media-breakpoint-up-sm {
                font-size: 16px;
            }
        }

        .g-public-upload-form-input-box {
            width: 100%;
            margin-top: 8px;

            @include media-breakpoint-up-sm {
                width: 527px;
            }

            .g-public-upload-form-title {
                font-family: 'Plus Jakarta Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                color: #000929;
                flex: none;
                order: 0;
                flex-grow: 0;
            }

            .g-public-upload-form-input {
                height: 48px;
                margin-top: 8px;
                font-family: 'Plus Jakarta Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #000929;
                opacity: 0.5;
            }
        }

        .g-upload-video-upload-container {
            cursor: pointer;
            width: 100%;
            margin: 32px 0;

            @include media-breakpoint-up-sm {
                width: 527px;
            }

            .g-upload-video-upload-box {
                display: flex;
                flex-direction: column;
                display: flex;
                width: 100%;

                .ant-upload-select {
                    width: 100%;
                    height: 180px;

                    .g-upload-video-upload-box-content {
                        background: #F0F6FF;
                        border: 2px dashed var(--primary);
                        border-radius: 8px;
                        height: 180px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .g-upload-video-upload-box-content-icon {
                            padding-right: 25px;
                        }

                        .g-upload-video-upload-box-content-text {

                            .g-upload-video-upload-box-title {
                                -webkit-user-select: none;
                                /* Safari */
                                -moz-user-select: none;
                                /* Firefox */
                                -ms-user-select: none;
                                /* IE10+/Edge */
                                user-select: none;
                                /* Standard */
                                display: flex;
                                font-size: 14px;

                                @include media-breakpoint-up-sm {
                                    font-size: 16px;
                                }
                            }

                            .g-upload-video-upload-box-description {
                                -webkit-user-select: none;
                                /* Safari */
                                -moz-user-select: none;
                                /* Firefox */
                                -ms-user-select: none;
                                /* IE10+/Edge */
                                user-select: none;
                                /* Standard */
                                display: flex;
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 16px;

                                @include media-breakpoint-up-sm {
                                    font-size: 12px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .g-public-upload-form-videos-table {
            width: 100%;

            @include media-breakpoint-up-sm {
                width: 527px;
            }

            .ant-table-thead {
                tr {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #84818A;
                }
            }

            .ant-table-tbody {
                tr {
                    td:first-child {
                        width: 52px;
                    }
                }

                .ant-table-cell-row-hover {
                    background-color: white;
                }

                .ant-table-cell {
                    border: none;
                }
            }

            .g-public-upload-product-url-input-container {
                display: flex;
                align-items: center;
                margin: 8px 0;

                .g-public-upload-product-url-delete-button {
                    height: 28px;
                    width: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    margin-left: 38px;
                    box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.20);
                    border: 1px solid #EBEBEB;
                    border-radius: 8px;
                }

                .g-public-upload-product-url-input {
                    height: 48px;
                    font-family: 'Plus Jakarta Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%;
                    color: #000929;
                    opacity: 0.5;
                }
            }

            .g-video-preview-container {
                height: 52px;
                width: 52px;
                position: relative;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
                border-radius: 4px;
                overflow: hidden;

                .g-video-preview-thumbnail {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: top center;
                }

                .g-video-preview-thumbnail-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
                }
            }
        }

        .g-public-upload-form-submit-button {
            width: 352px;
            height: 48px;
            margin: 44px 0;
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #FFFFFF;
        }
    }
}