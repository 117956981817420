.g-upgrade-your-account-modal-body {
    padding: 8px 0 0 0;

    .g-upgrade-your-account-modal-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #2E2C34;
        margin-bottom: 8px;
    }

    .g-upgrade-your-account-modal-title-secondary {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #6D7175;
    }
}

.g-upgrade-your-account-modal-footer {
    padding: 12px 29px 22px 37px;
    display: flex;
    flex-direction: column;
    gap: 21px;

    .g-upgrade-your-account-modal-footer-button {
        height: 44px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin: 0 !important; // necessary to override ant-d footer style
    }
}