.g-interaction-edit-summary-card-side-container {
  grid-area: side;
  align-self: start;
  position: sticky;
  top: 35px;

  .g-interaction-edit-summary-card-side-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 10px 9px rgba(16, 10, 85, 0.04);
    border-radius: 8px;

    .g-interaction-edit-summary-card-side-image-container {
      width: 42px;
      margin-bottom: 10px;

      .g-interaction-edit-summary-card-side-image {
        width: 100%;
      }
    }

    .g-interaction-edit-summary-card-side-text {
      margin-top: 15px;
      margin-bottom: 30px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #84818A;
    }

    .g-interaction-edit-summary-card-side-button-text {
      height: 40px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
    }
  }
}