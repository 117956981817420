.g-customization-color-input-container {
  display: flex;

  &.g-customization-color-reduce-margin {
      margin-top: -25px;
  }

  .g-customization-color-input-title {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
  }

  .g-customization-color-input {
      color: gray;
      width: 50px;
      margin-top: 8px;
      margin-right: 7px;
      line-height: 24px;

      &.g-customization-color-hex-input {
          width: 85px;
      }
  }
}