.g-interation-edit-your-questions-panel-title {
  padding-left: 10px;
}

.g-interation-edit-max-questions-text {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  text-align: center;
  opacity: 0.5;
}