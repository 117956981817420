.g-dashboard-confirm-date-modal {

    .ant-modal-content {
        .ant-modal-body {
            padding: 20px;
            .ant-modal-confirm-body-wrapper {
                .ant-modal-confirm-body {
                    .ant-modal-confirm-content {
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }
            }
        }
    }

}