.g-app-container {
    height: 100%;

    .g-fullscreen-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    aside.ant-layout-sider {
        z-index: 1001;
    }
}

// global classes
.g-play-button-tiny {
    position: absolute;
    height: 21px;
    width: 21px;
    top: calc(50% - 10px);
    left: calc(50% - 10px);

    span {
        height: 21px;
        width: 21px;
    }
    &:hover {
        opacity: 0.9;
    }
}

.g-play-button-small {
    position: absolute;
    height: 30px;
    width: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 15px);

    span {
        height: 30px;
        width: 30px;
    }
    &:hover {
        opacity: 0.9;
    }
}

.g-play-button-medium {
    position: absolute;
    height: 39px;
    width: 39px;
    top: calc(50% - 19px);
    left: calc(50% - 19px);

    span {
        height: 39px;
        width: 39px;
    }
    &:hover {
        opacity: 0.9;
    }
}

.g-play-button-large {
    position: absolute;
    height: 80px;
    width: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);

    span {
        height: 80px;
        width: 80px;
    }
    &:hover {
        opacity: 0.9;
    }
}