.g-need-help-card {
    margin: 16px 0 33px 0;
    padding: 30px;
    background-image: linear-gradient(to right, #1C1F4B, #1C1F4B, #1c1f4bc6, #49a8c7, #94e596);
    border-radius: 8px;

    .g-need-help-card-title {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
    }

    .g-need-help-card-description {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #C9D6F3;
    }

    .g-need-help-card-button {
        border-radius: 4px;
        border: 1px solid #2B306A;
        margin-top: 14px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: white;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}