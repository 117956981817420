.g-customization-color-eye-dropper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #e5e8e8;
  border-radius: 8px;
  position: relative;
  bottom: 50px;
}