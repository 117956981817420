@import 'assets/scss/mixing.scss';

.g-select-plan-table-container {
    overflow: auto;
    margin-bottom: 60px;
    @include custom-scrollbar;

    .g-select-plan-table {
        border-collapse: collapse;
        margin: 0 auto;

        @include media-breakpoint-up-md {
            margin: unset;
        }

        .g-select-plan-table-row {
            display: flex;
            flex-direction: column;
            height: 100%;

            &:not(:first-child) {
                display: none;
            }

            @include media-breakpoint-up-md {
                display: table-row-group;
                height: 72px;

                &:not(:first-child) {
                    display: table-row-group;
                }
            }

            &:nth-child(even) {
                background-color: #f2f2f2;
            }

            .g-select-plan-table-heading,
            .g-select-plan-table-data {
                padding: 8px;
            }

            .g-select-plan-table-heading {
                font-size: 16px;
                line-height: 20px;
                color: #2E2C34;
                width: 250px;
                padding: 0 26px;
                text-align: left;
                font-weight: 600;
                min-width: 250px;
            }

            .g-select-plan-table-data {
                padding: 0 12.5px;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;

                &.g-select-plan-table-card-data {
                    text-align: left;
                }

                button.g-select-plan-table-start-button {
                    height: 38px;
                    min-width: 146px;
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    padding: 9px 21px;
                }

                a.g-select-plan-table-start-button {
                    height: 38px;
                    min-width: 146px;
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;

                    span {
                        margin: 8px;
                    }
                }

                .g-select-plan-table-data-text {
                    max-width: 180px;
                    margin: 0 auto;
                }

                .g-select-plan-table-data-text-long {
                    max-width: 212px;
                    margin: 0 auto;
                }

                .first-letter-capital::first-letter {
                    text-transform: uppercase
                }
            }
        }
    }
}