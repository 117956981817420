.g-video-details-drawer-preview-container {
    width: 323px;
    height: 507px;

    .g-add-edit-video-details-drawer-preview {
        align-items: center;
        height: 100%;
        width: 100%;

        .g-product-preview-container {
            position: relative;
            height: 100%;
            width: 100%;

            .g-video-preview-thumbnail {
                height: 100%;
                width: 100%;
                border-radius: 8px;
                object-fit: cover;
                object-position: top center;
            }

            .g-product-preview-overlay {
                position: absolute;
                border-radius: 8px;

                top: 15px;
                left: 20px;

                .g-product-preview-overlay-product-image {
                    width: 44px;
                    height: 44px;
                    border-radius: 8px;
                    margin-bottom: 10px;
                    object-fit: cover;
                    background-color: white;
                }
            }

            .g-interaction-preview-overlay {
                position: absolute;
                border-radius: 8px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;

            }
        }
    }
}