.g-interaction-edit-question-card-type-container {
  flex: 1;

  .g-interaction-edit-question-card-type-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #1676F3;
    background: rgba(22, 118, 243, 0.1);
    border-radius: 4px;
    padding: 2px 8px;
  }

  .g-interaction-edit-question-card-type-saq-color {
    color: #1676F3;
    background: #1676F31A;
  }

  .g-interaction-edit-question-card-type-mc-color {
    color: #9747FF;
    background: #9747FF1A;
  }

  .g-interaction-edit-question-card-type-pc-color {
    color: #FF6424;
    background: #FF64241A;
  }

  .g-interaction-edit-question-card-type-cta-color {
    color: #4FBF67;
    background: #4FBF671A;
  }
  .g-interaction-edit-question-card-type-add-to-cart-color {
    color: #FFA043;
    background: rgba(255, 160, 67, 0.1);
  }
}