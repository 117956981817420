.g-other-text-checkbox-group {
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper {
        font-size: 12px;
        font-family: Manrope;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}