.g-embedded-tip-modal {
    .g-embedded-tip-modal-content {
        display: flex;
        align-items: center;
        flex-direction: column;

        .g-embedded-tip-modal-title {
            font-family: Inter;
            font-size: 28px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0px;
            text-align: center;
        }

        .g-embedded-tip-modal-body {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            margin-top: 20px;

            .g-embedded-tip-modal-body-text {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0px;
                text-align: center;
            }

            .g-embedded-tip-modal-link-container {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0px;
                text-align: center;
            }

            .g-embedded-tip-modal-snippet-container {
                background: #fefefe;
                border: 1px solid #ebebeb;
                border-radius: 8px;
                padding: 10px 20px;
                margin-bottom: 20px;
                max-width: 270px;

                .ant-typography {
                    display: flex;
                    font-family: "Plus Jakarta Sans";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 150%;
                    color: #84818a;
                }
            }
        }

        .g-embedded-tip-modal-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 20px;
            margin-top: 15px;
        }
    }
}
