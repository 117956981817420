@import 'assets/scss/mixing.scss';

.g-small-browser-overlay-component-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 1003;

    .g-small-browser-overlay-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
            height: 75px;
            width: 75px;
            margin-bottom: 25px;
        }

        h3 {
            font-family: Plus Jakarta Sans;
            font-size: 20px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: -0.01em;
            text-align: center;
            color: white;

        }

        p {
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            opacity: 0.5;
            color: white;

        }
    }

    @include gander-media-breakpoint-up-900 {
        display: none;
    }
}