.g-integration-card-container {
    display: flex;
    // margin: 20px 20px 0 20px;

    .g-integration-logo-image-container {
        height: 80px;
        width: 80px;
        min-width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F3F4F6;
        border-radius: 24px;
        margin-right: 24px;
    }

    .g-integration-card-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #22272F;
    }

    .g-integration-card-details {
        margin-top: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #454F5F;
    }

    .g-integration-card-connect-button {
        margin-top: 16px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        border: 1px solid #F2F2F2;
        border-radius: 4px;
        font-size: 12px;

        &.ant-btn:hover,
        &.ant-btn:focus,
        &.ant-btn:active {
            color: #fff;
            border-color: #40a9ff;
            background: #40a9ff;
        }

        .g-integration-card-connect-button-icon {
            margin: 0 0 0 8px;
        }
    }
}