@import 'assets/scss/mixing.scss';

.g-account-setup-tabs-main {
    height: calc(100% - 78px);

    .g-account-setup-tabs-container {
        background: #ffffff;
        height: 100%;

        .ant-tabs-nav {
            padding: 4px 35px 0 35px;

            &::before {
                border-bottom: unset;
            }
            .ant-tabs-nav-wrap {
                justify-content: center;
                @include media-breakpoint-up-md {
                    justify-content: unset;
                }
            }

            .ant-tabs-tab {
                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: #3c8ef9;
                    }
                }

                .ant-tabs-tab-btn {
                    color: rgba(28, 46, 69, 0.6);
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        .ant-tabs-content-holder {
            height: inherit;

            .ant-tabs-content {
                height: inherit;

                .ant-tabs-tabpane {
                    height: inherit;
                }
            }
        }
    }
}