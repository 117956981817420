.g-sortable-connected-video-component {
    .g-sortable-title-wrapper {
        width: 80%;
    }

    .g-manage-content-edit-video-list-linked-pages-container {
        width: 85%;

        a {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .g-video-list-page-link-text {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}