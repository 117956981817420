.g-interaction-edit-question-card-container {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 85px;
  border: 1px solid #EDEDED;
  box-shadow: 0px 10px 9px rgba(16, 10, 85, 0.04);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    border: 2px solid #3C8EF9;

    .ant-card {
      background: #E6F1FE;
    }
  }

  &:focus {
    outline: #3C8EF9 5px auto;
  }

  .ant-card {
    padding: 15px;
    border-radius: inherit;
  }

  .g-interaction-edit-question-card-top {
    gap: 5px;
    align-items: center;
    height: 20px;

    .g-interaction-edit-question-card-draggable{
      padding: 1px 5px;
    }
  }

  .g-interaction-edit-question-card-bottom {
    gap: 7px;
    align-items: center;
    padding-top: 13px;
    padding-left: 2px;

    .g-interaction-edit-question-card-badge {
      width: 20px;
      height: 20px;
    }

    .g-interaction-edit-question-card-question-text {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.g-interaction-edit-question-card-selected {
  border: 2px solid #3C8EF9;

  .ant-card {
    background: #E6F1FE;
  }
}