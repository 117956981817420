.g-add-edit-video-details-drawer-interaction-select-container {
  padding: 0px 6px;
  height: 100%;
  width: 55%;

  .g-add-edit-video-details-interaction-form {
    padding: 0 50px 0 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .g-add-edit-video-details-drawer-interaction-select-header {
      display: flex;
      justify-content: space-between;

      .g-add-edit-video-details-drawer-interaction-select-title {
        margin-bottom: 10px;
      }

      .g-add-edit-video-details-drawer-interaction-select-remove-button {
        color: red;
      }
    }

    .g-add-edit-video-details-drawer-interaction-select {
      border: 1px solid #EBEBEB;
      border-radius: 8px;
    }
  }
}

.g-add-edit-video-details-drawer-interaction-select-option {
  height: 85px;
  padding: 20px;

  .g-add-edit-video-details-drawer-interaction-select-option-name {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .g-add-edit-video-details-drawer-interaction-select-option-date {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #84818A;
  }
}

.g-add-edit-video-details-drawer-interaction-select-option-disabled {
  opacity: 0.5;
}

.g-add-edit-video-details-drawer-interaction-select-add-button {
  width: 350px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}