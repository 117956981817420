.g-interaction-edit-card-title-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  align-items: center;
  height: 65px;

  .g-interaction-edit-card-toggle-buttons-container {
    display: flex;
    gap: 10px;
    grid-column-start: 2;
    height: inherit;
    align-items: center;

    .g-interaction-edit-card-toggle-button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      height: inherit;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px;

      &.ant-btn-text {
        color: rgba(28, 46, 69, 0.6);
      }

      &:hover {
        color: var(--primary);
        border-bottom: 2px solid var(--primary);
      }
    }

    .g-interaction-edit-card-toggle-button-active {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      height: inherit;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px;
      color: var(--primary);
      border-bottom: 2px solid var(--primary);
    }
  }

  .g-interaction-edit-card-finish-buttons-container {
    display: flex;
    gap: 10px;
    margin-left: auto;
    align-items: center;
    width: 240px;
    height: 40px;

    .g-interaction-edit-card-preview-button-tooltip {
      height: 100%;

      button {
        height: 100%;
        background: #E1E1E1;
      }
    }


    .g-interaction-edit-card-publish-button {
      flex: 1;
      height: inherit;
    }
  }
}