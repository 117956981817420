.g-site-layout-background {
  .g-header-container {
    height: 78px;
    display: flex;
    justify-content: space-between;

    .g-header-left {
      display: flex;
      padding: 28px;
      align-items: center;

      .g-header-left-dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;

        .g-header-left-dropdown-title {
          padding-right: 9px;
          height: 33px;
          font-size: 24px;
          font-weight: 600;
          font-family: "Manrope", sans-serif;
          line-height: 32.78px;
        }
      }
    }

    .g-header-right {
      display: flex;
      padding-right: 34px;
      align-items: center;

      .g-header-menu-dropdown {
        display: flex;
        cursor: pointer;

        .g-header-right-user-avatar {
          display: flex;
          height: 36px;
          width: 36px;
          img {
            border-radius: 50%;
          }
        }

        .g-header-right-user-detail-container {
          height: 36px;
          padding-left: 8px;

          .g-header-right-user-name {
            font-family: "Manrope", sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 19.12px;
            height: 19px;
          }

          .g-header-right-user-type {
            font-family: "DM Sans", sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 15.62px;
            height: 16px;
          }
        }

        .g-header-right-dropdown {
          display: flex;
          align-items: flex-start;
          padding-left: 5px;
          padding-top: 2px;

          svg {
            fill: red;
          }
        }
      }
    }
  }
}

.g-header-left-menu-dropdown-override {
  // important is needed to override antd's default values
  min-width: 150px !important;
  max-width: 150px;
}