.g-instagram-modal-step-one-body {
  width: 365px;

  .g-instagram-modal-input {
    height: 44px;
    background: #FEFEFE;
    border-radius: 8px;

    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    color: #84818A;
  }

  .g-instagram-modal-input-tip {
    opacity: 0.3;
  }

  .g-instagram-modal-next-button {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 75px;
  }
}