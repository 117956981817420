@import 'assets/scss/mixing.scss';

.g-sign-up-form-divider.amplify-divider {
    display: none;

    @include media-breakpoint-up-md {
        display: block;
    }
}

.g-sign-up-form-name-fields {
    @include media-breakpoint-up-md {
        display: flex;
        gap: 15px;
    }

    .g-sign-up-form-first-name {
        margin-bottom: 17px;

        @include media-breakpoint-up-md {
            margin-bottom: unset;
        }
    }
}