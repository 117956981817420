.g-account {
    padding-top: 30px;

    .g-account-settings {
        .g-account-settings-row {
            .g-account-settings-col {
                .g-account-setting-email-verification-text-span-link {
                    color: #1890ff;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    .g-account-input {
        width: 95%;
        height: 48px;
        border: 1px solid #d9d9d9;
    }

    .g-account-form {
        margin-top: 10px;

        .g-account-form-item {
            margin-bottom: 10px;
        }
    }

    .g-account-divider {
        border: 1px solid #e3e3e3;
    }

    .g-account-title-3 {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.35;
    }

    .g-account-title-5 {
        margin-bottom: 0;
        font-weight: 600;
        line-height: 27px;
    }

    .g-account-text {
        font-weight: 500;
        color: black;
        line-height: 20px;
        padding-bottom: 20px;
    }

    .g-account-subtitle {
        color: rgba(132, 129, 138, 1);
        padding-bottom: 40px;
        font-size: 13px;

        .g-account-subtitle-date-container {
            color: black;
            font-weight: bold;
        }
    }

    .g-account-remove-button {
        margin-right: 10px;
        text-decoration: solid;
        font-weight: bold;
        font-size: medium;
        color: red;
    }

    .g-account-save-button {
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 20px;
        text-decoration: solid;
        font-weight: bold;
        font-size: medium;
    }

    .g-account-profile {
        margin-bottom: 30px;

        .ant-upload.ant-upload-select-picture-card {
            border-radius: 50%;
        }

        .g-account-profile-avatar-uploader {
            width: 100%;
            border-radius: 50%;
        }

        .g-account-remove-button {
            width: 140px;
        }

        .g-account-profile-layout {
            padding-left: 30px;

            .g-account-subtitle {
                padding-bottom: 10px;
            }
        }
    }

    .g-account-setting-container-for-buttons {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        padding-bottom: 30px;

        .g-account-remove-button {
            color: black;
            width: 140px;
        }
    }

    .g-account-reset-password {
        .g-account-save-button {
            gap: 5px;
            margin-left: 0;
            margin-right: 10px;
            text-decoration: solid;
            font-weight: bold;
            font-size: medium;
        }
    }

    .g-account-setting-phone-number-input {
        width: 95%;
        height: 48px;

        .PhoneInputCountry {
            border-radius: 4px 0px 0px 4px;
            border: 1px solid #d9d9d9;
            border-right: none;
            margin-right: 0px;
            width: 60px;
            justify-content: space-around;

            .PhoneInputCountryIcon {
                margin-left: 8px;
                border-radius: 2px;
            }

            .PhoneInputCountrySelectArrow {
                margin-right: 8px;
                font-weight: 600;
                --PhoneInputCountrySelectArrow-width: 6px;
                --PhoneInputCountrySelectArrow-borderWidth: 2px;
            }
        }

        .PhoneInputInput:focus {
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            outline: none;
        }

        .PhoneInputInput {
            border-radius: 0px 4px 4px 0px;
            border: 1px solid #d9d9d9;
            font-weight: 350;
            height: 48px;
            font-size: 16px;
            font-family: sans-serif;
            font-variant: tabular-nums;
            line-height: 1.5715;
            padding-left: 11px;
        }
    }
}