.g-widget-preview-container {
    height: 100%;
    width: 85%;
    min-width: 630px;
    background: #ffffff;
    box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
    border-radius: 8px;
    margin-left: 25px;

    .g-preview-state-loader {
        display: flex;
        height: calc(100% - 72px);
        justify-content: center;
        align-items: center;
    }

    .g-customization-section-title {
        display: flex;
        height: 70px;
        padding: 22px 0px 14px 23px;
        border-bottom: 1px solid var(--borderLightColor);

        .g-rounded-badge-number-button {
            padding-top: 1px;
            margin-right: 11px;
            background-color: black;
            border-color: black;
            color: white;
            font-size: 16px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .g-widget-preview-card-container {
        height: calc(100% - 135px);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 21px 28px 41px 28px;
        border-radius: 7px;

        .g-widget-preview-view-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            border: 1px solid var(--borderLightColor);
            border-radius: 7px;
            box-shadow: 0px 3.3871px 16.9355px rgba(14, 8, 84, 0.08);

            .g-widget-preview-iframe {
                flex: 1;
                border: unset;
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;
            }
        }

        .g-widget-preview-iframe {
            flex: 1;
            border: unset;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
        }

        .g-widget-preview-desktop-ratio {
            width: 90%;
            aspect-ratio: 16 / 10;
        }

        .g-widget-preview-mobile-ratio {
            aspect-ratio: 9 / 16;

            .g-widget-preview-mobile-header {
                border-top-left-radius: 7px;
                border-top-right-radius: 7px;
            }
        }

        .g-widget-preview-tablet-ratio {
            width: 90%;
            aspect-ratio: 16 / 10;
        }
    }

    .g-widget-preview-footer-page-settings-container {
        height: 70px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        border-top: 1px solid var(--borderLightColor);

        .g-widget-preview-footer-page-settings-left {
            display: flex;
            justify-content: left;
            align-items: center;
            margin: 0 23px;

            .g-widget-preview-footer-screen-type-button {
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 51px;
                height: 42px;
                border-radius: 8px;

                .g-widget-preview-footer-screen-type-button-svg {
                    fill: gray;
                    stroke: gray;
                }

                &.ant-btn-primary {
                    .g-widget-preview-footer-screen-type-button-svg {
                        fill: var(--primary);
                        stroke: var(--primary);
                    }
                }
            }
        }

        .g-widget-preview-footer-page-settings-center {
            display: flex;
            justify-content: center;
            margin-left: 20%;
            margin-right: 20%;
            grid-column: 3/3;

            justify-self: center;
            align-items: center;
            margin: 0 23px;

            .g-widget-preview-footer-button {
                height: 38px;
                margin-left: 10px;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                min-width: 110px;
            }
        }
    }
}