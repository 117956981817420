.g-interaction-edit-responses-card-table-cell-images-container {
  display: flex;
  flex-wrap: wrap;

  .g-interaction-edit-responses-card-table-cell-image-container {
    height: 50px;

    .g-interaction-edit-responses-card-table-cell-image {
      height: 100%;
      object-fit: cover;
    }
  }
}