.g-login-layout-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 30px;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .g-disabled {

    input,
    select {
      pointer-events: none;
      color: var(--amplify-components-fieldcontrol-disabled-color);
      cursor: var(--amplify-components-fieldcontrol-disabled-cursor);
      border-color: var(--amplify-components-fieldcontrol-disabled-border-color);
      background-color: var(--amplify-components-fieldcontrol-disabled-background-color);
    }
  }

  .amplify-label {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }

  .amplify-button--link {
    &:focus {
      background-color: unset;
      border: unset;
      outline: unset;
      box-shadow: unset;
      color: var(--primary);
    }

    &:hover {
      color: var(--primary);
    }
  }

  .g-sign-up-acknowledgement-checkbox {
    .amplify-visually-hidden {
      position: unset;
    }

    .g-sign-up-checkbox-text {
      font-size: 13px;
      font-weight: 500;
      font-family: 'Manrope';
      color: #84818A;
      margin-top: 10px;
    }
  }
}

.g-shopify-install-link {
  text-align: center;
}

.g-sign-up-website-prefix-text {
  padding: 8px;
}


[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(--primary);
  --amplify-components-button-primary-hover-background-color: var(--hover);
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-color: var(--primary);
  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-tabs-item-border-color: unset;
  --amplify-components-tabs-item-active-border-color: var(--primary);
  --amplify-components-tabs-item-active-color: var(--primary);
  --amplify-components-tabs-item-focus-color: var(--primary);
  --amplify-components-tabs-item-hover-color: var(--primary);
  --amplify-components-tabs-item-color: var(--primary);
  --amplify-components-authenticator-router-border-color: transparent;
  --amplify-components-textfield-border-color: var(--borderColor);
  --amplify-components-button-border-color: var(--borderColor);
  --amplify-components-selectfield-border-color: var(--borderColor);
  --amplify-components-textfield-focus-border-color: var(--borderColor);
  --amplify-components-fieldcontrol-focus-box-shadow: transparent;
  --amplify-components-button-focus-box-shadow: transparent;
  --amplify-components-selectfield-focus-border-color: var(--borderColor);
  --amplify-components-button-focus-border-color: var(--borderColor);
  --amplify-components-passwordfield-button-focus-border-color: var(--borderColor);
  --amplify-components-button-active-background-color: unset;
  --amplify-components-button-active-border-color: var(--borderColor);
  --amplify-components-button-active-color: var(--borderColor);
  --amplify-components-passwordfield-button-active-border-color: var(--borderColor);
  --amplify-components-checkbox-icon-background-color: var(--primary);
  --amplify-components-link-color: var(--primary);
  --amplify-components-link-hover-color: var(--hover);
  --amplify-components-link-visited-color: var(--primary);
}