.g-apply-global-customization-modal {
    width: 463px;
    height: 273px;
    box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
    border-radius: 8px;

    .g-modal-title {
        height: 36px;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #2E2C34;
        margin-bottom: 10px;
    }

    .g-modal-description {
        margin: 0 20px 0 20px;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #6D7175;
    }

    .ant-modal-body {
        padding: 24px 20px;
        width: 412;
    }

    .ant-modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 20px;

        .ant-btn-default {
            // Button Style
            width: 180px;
            height: 44px;
            background: white;
            border-radius: 4px;

            // Text Style
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #2F2F2F;

        }

        .ant-btn-primary {
            // Button Style
            width: 180px;
            height: 44px;
            background: #3C8EF9;
            border-radius: 4px;

            // Text Style
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
        }
    }
}