@import '../../../../../../assets/scss/mixing.scss';

.g-instagram-modal-footer-card {
  height: 100px;
  width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.g-instagram-modal-card-wrapper {
  @include custom-scrollbar;
  height: 50vh;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 2px;
  overflow: auto;
  scroll-behavior: smooth;
}