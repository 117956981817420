@import 'assets/scss/mixing.scss';

.g-video-details-container {
    width: 100%;

    .g-video-details-tabs-container {
        background: #FFFFFF;
        box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
        border-radius: 8px;
        height: 100%;

        .ant-tabs-nav {
            padding: 12px 35px 0 35px;

            .ant-tabs-tab-btn {
                color: #3C8EF9;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .ant-tabs-content-holder {
            padding: 0 35px 10px 35px;
            overflow: auto;
            @include custom-scrollbar;
        }
    }
}

.g-add-edit-video-details-drawer-title-container {
    display: flex;
    align-items: center;

    .g-title-video-player {
        height: 76px;
        width: 76px;
        margin: 10px 24px 10px 10px;
        border-radius: 5px;

        .g-video-preview-thumbnail {
            border-radius: 5px;
        }
    }

    .g-title-video-name {

        .g-manage-content-add-edit-video-details-drawer-title-input {
            margin-bottom: -10px;
            font-size: 20px;
            width: 350px;
        }

        .g-manage-content-add-edit-video-details-drawer-title {
            max-width: 400px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .g-manage-content-add-edit-video-details-drawer-title-edit-icon {
            margin: 0 0 15px 15px;

            span {
                height: 100%;
                width: 23px;
                cursor: pointer;
            }
        }
    }

}

.g-manage-content-add-edit-video-details-drawer {

    .ant-drawer-body {
        display: flex;
        padding: unset;

        .g-video-details-drawer-body-wrapper {
            padding: 15px 0 0 0;
            position: relative;
            display: flex;
            width: 100%;
            overflow: auto;
            @include custom-scrollbar;


            .g-add-edit-video-details-drawer-loader-container {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 100%;
                height: 100%;
                background-color: #eceaea;
                z-index: 1000;
                opacity: 0.4;
                filter: alpha(opacity=40);

                .g-add-edit-video-details-drawer-loader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    padding-bottom: 110px;
                }
            }

            .g-add-edit-video-details-drawer-content-container {
                padding: 0px 6px;
                height: 100%;
                width: 55%;

                .g-add-edit-video-details-form {
                    padding: 0 50px 0 0;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    max-width: 100%;


                    .g-add-edit-video-details-form-field-title {
                        display: flex;
                        gap: 8px;
                        align-items: baseline;

                        h3 {
                            margin-bottom: 5px;
                        }
                    }

                    .g-video-details-form-field-help-text {
                        font-family: 'Plus Jakarta Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 150%;
                        color: #000929;
                        opacity: 0.5;
                    }

                    .g-add-edit-video-details-form-field-title~.g-add-edit-video-details-form-field-title {
                        padding-top: 20px;
                        justify-content: space-between;
                    }

                    .g-add-edit-video-details-form-new-page-title {
                        line-height: 25px;
                    }

                    .g-add-edit-video-details-form-select-page {
                        margin: 5px 0;
                        width: 100%;
                    }

                    .g-add-edit-video-details-connected-video-section {
                        flex: 1;
                        height: calc(100% - 200px);

                        .g-add-edit-video-details-connected-video-section-title-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 23px;
                        }

                        .g-list-header {
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            text-transform: uppercase;
                            padding: 13px 20px;
                            background-color: #efefef;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            font-family: DM Sans;
                            font-weight: 700;
                            color: #84818A;
                        }

                        .g-add-edit-video-details-list {
                            @include custom-scrollbar;
                            margin: 0 0 20px 0;
                            overflow: overlay;
                            min-height: 250px;
                            max-height: 65%;
                        }
                    }
                }
            }

            .g-video-details-drawer-preview-container {
                width: 323px;
                height: 507px;
            }
        }
    }
}