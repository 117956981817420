.g-interaction-breadcrumbs-container {
  display: flex;
  align-items: center;
  color: #727279;

  .g-interaction-breadcrumbs-parent-button {
    padding: 0px 20px 0px 10px;
    color: #727279;

    &:hover {
      color: var(--hover);
    }
  }

  .g-interaction-breadcrumbs-arrow {
    display: flex;
    align-items: center;
  }

  .g-interaction-breadcrumbs-name {
    padding-left: 8px;
    margin-left: 12px;
    padding-right: 8px;
    color: #222124;
    background: #EFEFEF;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    max-width: 350px;
    align-items: center;
    letter-spacing: -0.05px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}