.g-interaction-edit-summary-card-main-card {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-right: 35px;
  border: 1px solid #E2E2E2;
  box-shadow: 0px 10px 9px rgba(16, 10, 85, 0.04);
  border-radius: 8px;

  .g-interaction-edit-summary-card-main-card-type-container {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;

    .g-interaction-edit-summary-card-main-card-type {
      padding: 3px 11px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      font-size: 17.1429px;
      line-height: 23px;
      border-radius: 5.71429px;
    }

    .g-interaction-edit-summary-card-main-card-type-image-container {
      height: 30px;

      .g-interaction-edit-summary-card-main-card-type-image {
        height: 100%;
      }
    }

    .g-interaction-edit-summary-card-main-card-type-saq-color {
      color: #1676F3;
      background: #1676F31A;
    }

    .g-interaction-edit-summary-card-main-card-type-mc-color {
      color: #9747FF;
      background: #9747FF1A;
    }

    .g-interaction-edit-summary-card-main-card-type-pc-color {
      color: #FF6424;
      background: #FF64241A;
    }

    .g-interaction-edit-summary-card-main-card-type-cta-color {
      color: #4FBF67;
      background: #4FBF671A;
    }
    .g-interaction-edit-summary-card-main-card-type-add-to-cart-color {
      color: white;
      background: purple;
    }
  }

  .g-interaction-edit-summary-card-main-card-title {
    margin-bottom: 4px;
  }

  .g-interaction-edit-summary-card-main-card-sub-title {
    margin-bottom: 40px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #84818A;
  }
}