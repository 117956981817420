@import 'assets/scss/mixing.scss';

.g-billing-subscription-component {
    padding: 14px 0 10px 0;
    .g-billing-usage-cycle-wrapper {
        display: flex;
        justify-content: space-between;
    }

    h3.ant-typography {
        margin-bottom: 4px;
    }

    .g-billing-subscription-subtitle {
        color: #84818A;
        font-weight: 500;
        font-size: 12px;

        .ant-btn {
            font-size: inherit;
        }
    }

    .g-subscription-table-container {
        margin: 40px 0 20px 0;

        h4.ant-typography {
            font-weight: 700;
        }

        .g-subscription-plans-loading {
            height: 250px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .g-subscription-billing-question-title {
            margin-right: 5px;
            font-weight: 600;
            font-size: 12px;
        }

        .g-subscription-billing-question-detail {
            font-weight: 500;
            font-size: 12px;
            color: #84818A;
        }
    }
}