.g-interactions-column-header {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Grey */
  color: #84818A;
}