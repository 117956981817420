@import 'assets/scss/mixing.scss';

.g-customize-page-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    .g-customize-page-path {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #727279;
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 500;

        .g-customize-page-path-root {
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;

            .g-customize-page-path-root-name {
                color: #727279;
            }
        }


        .g-customize-page-path-page-name {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 8px;
            gap: 10px;
            width: auto;
            height: 28px;
            background: #EFEFEF;
            border-radius: 5px;
        }
    }

    .g-customize-page-settings-container {
        height: 100%;
        display: flex;
        overflow: auto;
        @include custom-scrollbar;
    }

    .g-customize-page-tabs-container {
        width: 15%;
        min-width: 300px;
        background: #FFFFFF;
        box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
        border-radius: 8px;
        overflow: auto;
        @include custom-scrollbar;

        .ant-tabs-nav-wrap {
            display: inline-block;
        }
        
        .ant-tabs-nav {
            margin: auto;
            font-family: 'Inter';
            font-weight: 500;
            color: #A1A0A3;
            margin: 0;

            .ant-tabs-nav-list {
                display: flex;
 
                .ant-tabs-tab {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    height: 60px;
                }
            }
        }

        .ant-tabs-content-holder {
            .ant-tabs-content {
                height: 100%;

                .ant-tabs-tabpane-active {
                    height: 100%;
                }
            }
        }

        .ant-tabs-nav-operations {
            display: none;
        }
    }
}