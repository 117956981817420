@import 'assets/scss/mixing.scss';

.g-interaction-edit-responses-card-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 110px auto;
  margin: 5px 25px 15px 25px;

  .g-interaction-edit-responses-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .g-interaction-edit-responses-card-title-container {
      display: flex;
      flex-direction: column;

      .g-interaction-edit-responses-card-title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #2E2C34;
      }

      .g-interaction-edit-responses-card-sub-title {
        display: flex;
        gap: 5px;
        margin-top: 5px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #84818A;

        .g-interaction-edit-responses-card-sub-title-image-container {
          display: flex;
          justify-content: center;
          padding: 1px;

          .g-interaction-edit-responses-card-sub-title-image {
            width: 16px;
            padding: 2px 0px;
          }
        }
      }
    }

    .g-interaction-edit-responses-card-header-button {
      height: 44px;
      width: 44px;
      margin-right: 10px;
    }
  }

  .g-interaction-edit-responses-card-table-container {
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;

    .g-interaction-edit-responses-card-table {
      padding: 15px 10px;
      margin: 10px;
      height: 100%;
      overflow: auto;
      @include custom-scrollbar;

      .g-interaction-edit-responses-card-table-row {
        height: 65px;
      }
    }
  }
}