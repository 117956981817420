.g-blinking-message {
  text-transform: uppercase;
  color: var(--color-black);
  font-weight: bold;
  animation: blink 2s infinite;
  text-align: center;
}

@keyframes blink {
  0% {
    color: var(--color-black);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    color: var(--color-gray);
  }

  100% {
    opacity: 0;
  }

}