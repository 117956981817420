@import 'assets/scss/mixing.scss';

.g-interation-edit-preview-panel-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .g-interation-edit-preview-panel-body-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .g-interation-edit-preview-panel-body-grid {
      display: grid;
      grid-template-columns: 230px;
      grid-template-rows: 420px;
      grid-template-areas: 
      "main";

      .g-interation-edit-preview-panel-thumbnail-image {
        grid-area: main;
        max-width: 100%;
        max-height: 100%;
        border-radius: 2px;
        box-shadow: 0px 5px 25px #00000014;
      }

      .g-interation-edit-preview-panel-thumbnail-container {
        grid-area: main;
        display: grid;
        grid-template-columns: auto 50px;
        grid-template-rows: auto 200px;
        grid-template-areas: 
        ". . ."
        "body body body";

        .g-interation-edit-preview-panel-thumbnail-image {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    // @media (min-height: 750px) {
    //   .g-interation-edit-preview-panel-body-grid {
    //     grid-template-rows: 50px 420px;
    //   }
    // }
  }
}