.g-interaction-edit-summary-card-main-card-picture-choice-image-container {
  height: 100px;

  .g-interaction-edit-summary-card-main-card-picture-choice-image {
    height: 100%;
  }
}

.g-interaction-edit-summary-card-main-card-choice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .g-interaction-edit-summary-card-main-card-choice-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #2F2F2F;
  }

  .g-interaction-edit-summary-card-main-card-choice-sub-title-container {
    align-self: flex-end;
    display: flex;
    gap: 15px;
    align-items: center;

    .g-interaction-edit-summary-card-main-card-choice-sub-title-count {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #84818A;
    }

    .g-interaction-edit-summary-card-main-card-choice-sub-title-percentage {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #2F2F2F;
    }
  }
}

.g-interaction-edit-summary-card-main-card-choice-body {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 22px;
  margin-bottom: 24px;

  .g-interaction-edit-synnary-card-main-card-choice-views {
    grid-column-start: 1;
    grid-row-start: 1;
    background: #EAEAEA;
    border-radius: 4px;
  }

  .g-interaction-edit-synnary-card-main-card-choice-submitted {
    grid-column-start: 1;
    grid-row-start: 1;
    background: var(--primary);
    border-radius: 4px;
  }
}