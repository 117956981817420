@import 'assets/scss/mixing.scss';

.g-auth-layout-wrapper {
    display: flex;
    height: 100vh;
    overflow: hidden;
    --amplify-components-authenticator-form-padding: var(--amplify-space-medium);
    padding-bottom: 20px;

    @include media-breakpoint-up-md {
        height: 100%;
        --amplify-components-authenticator-form-padding: var(--amplify-space-xl); // reset to default value
        padding-bottom: unset;
    }
}